import type { Currency } from "crq-types";
import { formatNumber } from "crq-utils";

export function getMillionsString({
  currency,
  number,
}: {
  currency?: Currency;
  number: number;
}) {
  return [currency, getMillions(number), "mn"].filter(Boolean).join(" ");
}

export function getMillions(number: number, type?: "number" | "string") {
  if (typeof number !== "number" || isNaN(number)) {
    return 0;
  }

  switch (type) {
    case "number":
      return number / 1_000_000;
    case "string":
    default:
      return formatNumber({ number: number / 1_000_000 });
  }
}
