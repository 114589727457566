import { Box, Card, CardContent, CardProps, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

import MeasureIndicator from "../../molecules/measure-indicator/measure-indicator";

export interface TitleCardProps {
  /** SVG icon */
  icon?: string;
  /** Title displayed to the left */
  title: string;
  /** Component displayed in the right corner */
  endSlot?: ReactNode;
  /** Component to be displayed in the left corner */
  startSlot?: ReactNode;
  /** Measure indicators */
  indicators?: {
    lastUpdatedDate?: string;
    lastUpdatedBy?: string;
    status?: string;
    title?: string;
  }[];
  cardProps?: CardProps;
  questionnaireSubmittedAt?: string;
  questionnaireSubmittedBy?: string;
}

export const TitleCard: FC<TitleCardProps> = ({
  icon,
  title,
  indicators,
  endSlot,
  startSlot,
  cardProps = {},
}) => {
  return (
    <Card
      data-testid="TitleCard"
      sx={{
        borderRadius: "12px",
        boxShadow: "none",
        height: "100%",
        width: "100%",
        flex: "1 1 auto",
        "@media print": {
          border: "1px solid rgba(0, 0, 0, 0.12)",
          pageBreakBefore: "always",
        },
        ...cardProps.sx,
      }}
    >
      <CardContent
        sx={{
          height: 65,
          padding: 2,
          pb: 2,
          "&:last-child": {
            pb: 2,
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">{title}</Typography>
        {icon && (
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            {startSlot}
            <Box
              component="img"
              sx={{
                background: "white",
                borderRadius: "12px",
                width: "36px",
                height: "36px",
                padding: "5px",
                mr: 2,
              }}
              src={icon}
            />
          </Box>
        )}
        <Box>
          {indicators?.map(
            (indicator, index) =>
              indicator.status &&
              indicator.lastUpdatedDate && (
                <MeasureIndicator
                  key={index}
                  title={indicator.title}
                  status={indicator.status}
                  lastUpdatedDate={indicator.lastUpdatedDate}
                  lastUpdatedBy={indicator.lastUpdatedBy}
                />
              )
          )}
          {endSlot}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TitleCard;
