import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";

import type {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
} from "../form-field";

export const CHECKBOX_FORM_FIELD_TYPE = "checkbox" as const;

export type CheckBoxFormFieldDefinition = BaseFormFieldDefinition<
  typeof CHECKBOX_FORM_FIELD_TYPE
> & {
  type: typeof CHECKBOX_FORM_FIELD_TYPE;
};

export function CheckBoxFormField({
  disabled,
  errorMessage,
  label,
  name,
  value,
  register,
  required,
}: CheckBoxFormFieldDefinition & AdditionalFormFieldProps) {
  return (
    <FormControl error={!!errorMessage} fullWidth>
      <FormControlLabel
        control={
          <Checkbox
            {...register(name, { disabled, required })}
            defaultChecked={value as boolean}
          />
        }
        label={<Typography sx={{ fontSize: "14px" }}>{label}</Typography>}
      />
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}
