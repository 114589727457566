import type {
  ConversionRates,
  CRQRun,
  CRQRunSuccess,
  Currency,
} from "crq-types";
import { convertCurrency } from "crq-utils";

import { getMillions } from "./get-millions";

type NumericalCRQRunProperty =
  | "annualAverageLoss"
  | "highExposureLoss"
  | "lossEventProbability";

export function getSeriesDataForYearByMonths<T extends NumericalCRQRunProperty>(
  runs: CRQRun[],
  property: T,
  year: number,
  currency?: Currency
): ({ date: string; value: number; rates?: ConversionRates } | null)[] {
  const filteredRuns = runs
    .filter(({ fqStatus }) => fqStatus === "Success")
    .filter(
      ({ date }) => date && new Date(date).getFullYear() === year
    ) as (CRQRun & CRQRunSuccess)[];
  const series: ({
    date: string;
    value: number;
    rates?: ConversionRates;
  } | null)[] = Array.from({
    length: 12,
  }).fill(null) as null[];

  for (const run of filteredRuns) {
    const month = new Date(run.date!).getMonth();
    const value = run[property];

    if (typeof value === "number") {
      series[month] = {
        date: run.date!,
        rates: run.rates,
        value: +getMillions(
          convertCurrency({ currency, rates: run.rates, value }),
          "number"
        ),
      };
    }

    if (typeof value === "string") {
      series[month] = {
        date: run.date!,
        rates: run.rates,
        value: +getMillions(
          convertCurrency({ currency, rates: run.rates, value: +value }),
          "number"
        ),
      };
    }
  }

  return series;
}
