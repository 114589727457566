import { Close, PublishedWithChanges, Sync } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

export type Log = {
  initiator: string;
  updatedFieldName: string;
  newValue: string;
  previousValue: string;
  objectName: string;
  parentObjectName: string;
  createdAt: string;
  usersWithAccessToLog: { name: string }[];
};

export type LogDrawerProps = {
  logs?: Log[];
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const LogDrawer: PimoReactComponent<LogDrawerProps> = ({
  logs,
  open,
  setOpen,
}) => {
  const theme = useTheme();
  return (
    <Drawer
      ModalProps={{
        keepMounted: false,
      }}
      variant="temporary"
      sx={{
        width: 500,

        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          boxShadow: "none",
          mt: "75px",
          border: "0px solid",
          borderRadius: "12px",
        },
      }}
      anchor="right"
      hideBackdrop={true}
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box
        sx={{
          boxShadow: "none",
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
          p: 2,
          "@media print": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              gap: 2,
            }}
          >
            <PublishedWithChanges sx={{ color: "#F86200" }} />
            <Typography sx={{ fontWeight: 500, fontSize: "1rem" }}>
              Change Log
            </Typography>
          </Box>
          <Button
            sx={{ color: "grey", minWidth: 0 }}
            onClick={() => setOpen(false)}
          >
            <Close />
          </Button>
        </Box>
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />
        <List sx={{ maxHeight: "80vh", overflow: "auto" }}>
          {logs?.map((entry) => (
            <ListItem
              key={entry.createdAt}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                gap: 2,
                width: 500,
              }}
            >
              <Accordion sx={{ boxShadow: "none" }}>
                <AccordionSummary>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Sync sx={{ color: "#2196F3" }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        {entry.initiator}
                        <Typography display="inline">
                          {""} updated {""}
                          {entry.objectName ? `${entry.objectName}` : ""} -{" "}
                          {entry.parentObjectName
                            ? `${entry.parentObjectName}`
                            : ""}
                        </Typography>

                        <Typography display="inline" sx={{ color: "#2196F3" }}>
                          {" "}
                          - Field: {entry.updatedFieldName}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          width: "100%",
                          textAlign: "left",
                          fontSize: "0.7rem",
                          color: "GrayText",
                        }}
                      >
                        {new Date(entry.createdAt).toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-evenly",
                      gap: 1,
                      p: 1,
                      px: 4,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
                        Previous Value
                      </Typography>
                      <Typography
                        sx={{
                          border: "1px solid",
                          borderColor: grey[100],
                          p: 1,
                          borderRadius: "12px",
                          maxHeight: 300,
                        }}
                      >
                        {entry.previousValue}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
                        New Value
                      </Typography>
                      <Typography
                        sx={{
                          border: "1px solid",
                          borderColor: grey[100],
                          p: 1,
                          borderRadius: "12px",
                          maxHeight: 300,
                        }}
                      >
                        {entry.newValue}
                      </Typography>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};
