import { create } from "zustand";

export type ZustandAppState = Record<string, unknown> & {
  patchState: (obj: { [key: string]: unknown }) => void;
};

export const useAppState = create<ZustandAppState>((set) => ({
  patchState: (obj: { [key: string]: unknown }) => {
    set(obj);
  },
}));
