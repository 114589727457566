import { Menu, PublishedWithChanges } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  styled,
  Toolbar,
  useTheme,
} from "@mui/material";
import type { FC, PropsWithChildren } from "react";

import {
  HeaderAccountSettings,
  HeaderAccountSettingsProps,
} from "../header-account-settings/header-account-settings";
import { Logo } from "../logo/logo";

export type HeaderProps = PropsWithChildren<{
  /** logo attributes */
  logo?: {
    /** logo alt attribute */
    alt?: string;
    /** logo for screen > 600px */
    big?: string;
    /** logo for screens <600px */
    small?: string;
  };
  /** method called to show or hide sidebar on MenuButton click */
  handleSidebarToggle?: () => void;
  /** handler for click on logo */
  handleLogoClick?: () => void;
  hideAccountSettings?: boolean;
  hideMenuButton?: boolean;
  enableLogs?: boolean;
  onLogsShow?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}> &
  HeaderAccountSettingsProps;

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  alignItems: "center",
  border: 0,
});

/** Component that displays at the top of the page */
export const Header: FC<HeaderProps> = ({
  children,
  entries,
  logo = {},
  username,
  hideAccountSettings,
  hideMenuButton,
  handleSidebarToggle,
  handleLogoClick,
  enableLogs,
  onLogsShow,
}) => {
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: 0,
        paddingTop: `env(safe-area-inset-top) !important`,
      }}
      data-testid="Header"
      style={{
        backgroundColor: theme.palette.common.white,
      }}
    >
      <StyledToolbar>
        <Button
          onClick={handleLogoClick}
          style={{ alignItems: "center", verticalAlign: "middle" }}
        >
          <Logo
            alt={logo?.alt ?? ""}
            big={logo?.big ?? ""}
            small={logo?.small ?? ""}
          />
        </Button>
        <Box sx={{ flexGrow: 1 }}>
          {!hideMenuButton && (
            <IconButton
              data-testid="header-button-toggleSidebar"
              color="primary"
              aria-label="open drawer"
              edge="start"
              sx={{ ml: 0 }}
              onClick={handleSidebarToggle}
            >
              <Menu
                sx={{
                  backgroundColor: theme.palette.secondary.light,
                  p: 0.5,
                  borderRadius: theme.shape.borderRadius,
                }}
                height={32}
                width={32}
              />
            </IconButton>
          )}
        </Box>
        <Box sx={{ alignItems: "center", display: "flex", gap: 1.5 }}>
          {children}
          {enableLogs && (
            <Button
              sx={{
                background: theme.palette.secondary.main,
                color: "#F86200",
                minWidth: 0,
                borderRadius: "8px",
              }}
              onClick={(e) => onLogsShow?.(e)}
            >
              <PublishedWithChanges />
            </Button>
          )}
          {!hideAccountSettings && (
            <HeaderAccountSettings entries={entries} username={username} />
          )}
        </Box>
      </StyledToolbar>
    </AppBar>
  );
};
