import { Box, Typography } from "@mui/material";

import { BaseLayoutElementDefinition } from "../layout-element";

export const FORM_TAB_TITLE_LAYOUT_ELEMENT_TYPE = "form-tab-title";

export type FormTabTitleLayoutElementDefinition =
  BaseLayoutElementDefinition & {
    type: typeof FORM_TAB_TITLE_LAYOUT_ELEMENT_TYPE;
    label: string;
    grayBoxText?: string;
  };

export function FormTabTitleLayoutElement(
  props: FormTabTitleLayoutElementDefinition
) {
  return (
    <>
      <Typography variant="h5" component="h2">
        {props.label}
      </Typography>
      {props.grayBoxText && (
        <Box
          sx={{
            background: "#F5F5F5",
            p: 2,
            borderRadius: "4px",
            mt: 2,
            fontStyle: "italic",
          }}
        >
          {props.grayBoxText}
        </Box>
      )}
    </>
  );
}
