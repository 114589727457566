import {
  Box,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useState } from "react";
import { Outlet } from "react-router-dom";

import { Header, HeaderProps } from "../../molecules/header/header";
import { Sidebar } from "../../molecules/sidebar/sidebar";
import { SidebarHeader } from "../../molecules/sidebar-header/sidebar-header";
import { MenuEntryInterface } from "../../types/menu-type";
import { Log, LogDrawer } from "../log-drawer/log-drawer";
const sidebarWidth = 266;

const MainStage = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: 0,
  },
  height: "100vh",
  overflow: "auto",
  ml: { md: `-${sidebarWidth}px` },
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export interface DefaultOverlayPartialAppState {
  /** is sidebar open */
  isSidebarOpen: boolean;
}
export type DefaultOverlayEventNames =
  | "overlay:logout"
  | "overlay:toggle-sidebar"
  | "overlay:click-logo"
  | "overlay:show-logs";

export interface DefaultOverlayProps {
  /** props for the header */
  header: HeaderProps;
  /** Is sidebar open */
  isSidebarOpen: DefaultOverlayPartialAppState["isSidebarOpen"];
  /** Sidebar menu entries */
  menuEntries: MenuEntryInterface[];
  fireEvent?: (eventName: DefaultOverlayEventNames) => void;
  canUserChangeCycle?: boolean;
  /** set to true if searching the menu entries should be enabled, if not defined search bar will not show */
  hasSearchFunctionality?: boolean;
  /** title and entries of the right side navigation, if undefined the sidebar will not show  */
  inPageNavigationSidebarProps?: {
    title: string;
    entries: { title: string; id: string }[];
  };
  logs?: Log[];
}

export const initialDefaultOverlayState = {
  isSidebarOpen: false,
} satisfies DefaultOverlayPartialAppState;

/** Default Overlay with header, sidebar, and Outlet (react-router) */
export const DefaultOverlay: PimoReactComponent<
  DefaultOverlayProps,
  DefaultOverlayEventNames
> = ({
  header,
  isSidebarOpen,
  menuEntries,
  fireEvent,
  hasSearchFunctionality,
  inPageNavigationSidebarProps,
  logs,
}: DefaultOverlayProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  return (
    <div data-testid="DefaultOverlay">
      <Box
        sx={{
          display: "flex",
          mt: `env(safe-area-inset-top) !important`,
        }}
      >
        <CssBaseline />
        <Header
          {...header}
          handleSidebarToggle={() => fireEvent?.("overlay:toggle-sidebar")}
          handleLogoClick={() => fireEvent?.("overlay:click-logo")}
          onLogsShow={() => {
            fireEvent?.("overlay:show-logs");
            setOpen(!open);
          }}
        />
        <Box
          component="nav"
          sx={{
            width: {
              xs: 0,
              sm: isSidebarOpen ? sidebarWidth : 0,
            },
            flexShrink: { xs: 0 },
          }}
          aria-label="sidebar menu"
        >
          <Sidebar
            hasSearchFunctionality={hasSearchFunctionality}
            entries={menuEntries}
            width={sidebarWidth}
            sidebarOpen={isSidebarOpen}
            toggleSidebar={() => fireEvent?.("overlay:toggle-sidebar")}
          />
        </Box>

        <MainStage>
          <SidebarHeader />
          <Outlet />
        </MainStage>
        {inPageNavigationSidebarProps && (
          <Box
            sx={{
              height: "100vh",
              minWidth: 300,
              maxWidth: 300,
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
              "@media print": {
                display: "none",
              },
            }}
          >
            <SidebarHeader />
            <Typography sx={{ fontWeight: 500, fontSize: "1.2rem", mb: 1 }}>
              {inPageNavigationSidebarProps.title}
            </Typography>
            <List
              sx={{
                borderLeft: "3px solid",
                borderColor: theme.palette.primary.main,
              }}
            >
              {inPageNavigationSidebarProps.entries.map((entry) => (
                <ListItem
                  onClick={() => {
                    const section = document.getElementById(entry.id);

                    if (section) {
                      section.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  key={entry.id}
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText primary={entry.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        <LogDrawer logs={logs} open={open} setOpen={setOpen} />
      </Box>
    </div>
  );
};
