import { UpdateStatus } from "crq-types";

export const APP_ROUTES = {
  dashboard: "/",
  overview: "/oe-overview",
  report: "/oe-reports/:id",
  editQuestionnaire: "/oe-reports/:id/edit",
  login: "/login",
} as const;

export const STATUS_COLORS = {
  unknown: "#c4c4c4",
  outdated: "#f44336",
  "to-be-updated": "#f9a825",
  "up-to-date": "#4caf50",
} as const satisfies Record<UpdateStatus, string>;
