import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useTheme,
} from "@mui/material";

import { PimoReactComponent } from "../lib/component";

type LoginBoxProps = {
  title: string;
  fireEvent?: (eventType: "component:click") => void;
};

export const LoginBox: PimoReactComponent<LoginBoxProps, "component:click"> = ({
  title,
  fireEvent,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        px: "32px",
        py: "128px",
      }}
    >
      <Card
        sx={{
          p: "32px",
          background: "#DFEFF2",
          borderRadius: {
            xs: "3px",
            md: "5px",
            xl: "10px",
          },
          "@media print": {
            border: "1px solid rgba(0, 0, 0, 0.12)",
          },
        }}
      >
        <CardContent
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "lighter",
              color: theme.palette.primary.main,
              mb: "32px",
            }}
          >
            {title}
          </Typography>
          <Button
            onClick={() => fireEvent?.("component:click")}
            variant="outlined"
            sx={{
              borderRadius: "5px",
            }}
          >
            Login
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};
