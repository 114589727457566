/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { Fragment } from "react";
import {
  createBrowserRouter,
  type NavigateOptions,
  Outlet,
  type Params,
  type RouteObject,
} from "react-router-dom";

import { DefaultLayout } from "./default-layout";
import { EventEmitter } from "./event-emitter";
import { Layout } from "./layout";
import { Route, RouteParameters } from "./route";
import { View } from "./view";

export interface RouterEventPayload<T extends string | never = never> {
  readonly path: string;
  readonly parameters?: Params<T>;
}

export type RouterEventType =
  | "navigated-to-path"
  | "route-loaded"
  | "new-router-created";

export class Router<AppState> extends EventEmitter<
  RouterEventType,
  RouterEventPayload
> {
  public router = createBrowserRouter([{}]);
  private routes: Route<any, AppState, any>[] = [];
  private overlay?: View<AppState, any>;

  private lastRouteEvent?: RouterEventPayload;

  /** method relying on the react-router-dom api to generate all the routes */
  private buildRoute(route: Route<any, AppState, any>): RouteObject {
    return {
      path: route.path,
      children: route.children.map((child) => this.buildRoute(child)),
      element: route.children.length ? (
        <>
          {route.view.render()}
          {route.isRenderingAllChildren
            ? route.children.map((child, index) => (
                <Fragment key={index}>{child.view.render()}</Fragment>
              ))
            : null}
          <Outlet />
        </>
      ) : (
        route.view.render()
      ),
      loader: ({ params }) => {
        // only call events in the next tick as otherwise the app bootstrapping
        // process might not yet be done and events might be broadcast even though
        // listeners aren't yet setup
        setTimeout(() => {
          this.fireRouteEvent({ path: route.path, parameters: params, route });
        });
        // return needed as `loader` usually returns payload data
        return {};
      },
    } satisfies RouteObject;
  }
  fireRouteEvent(routeEvent: {
    path: string;
    parameters: Params<string>;
    route: Route;
  }) {
    if (JSON.stringify(this.lastRouteEvent) === JSON.stringify(routeEvent)) {
      return;
    }

    routeEvent.route.fireRouteEvent("load", {
      path: routeEvent.path,
      parameters: routeEvent.parameters,
    });
    this.lastRouteEvent = routeEvent;

    this.fireEvent("route-loaded", routeEvent);
  }

  private buildOverlayRoute(
    overlay: View<AppState, any>,
    routes: Route<any, AppState, any>[]
  ) {
    return {
      element: overlay.render(),
      children: routes
        /** if a route should not be visible for some reason or is outside overlay it should not be rendered */
        .filter((route) => route.isOverlayChild && route.isVisible)
        .map((route) => this.buildRoute(route)),
    } satisfies RouteObject;
  }

  private buildRouter() {
    try {
      const routesNotChildrenOfOverlay = this.routes
        .filter((x) => !x.isOverlayChild)
        .map((route) => this.buildRoute(route));
      this.router = createBrowserRouter(
        this.overlay
          ? [
              this.buildOverlayRoute(this.overlay, this.routes),
              ...routesNotChildrenOfOverlay,
            ]
          : this.routes.map((route) => this.buildRoute(route))
      );
      this.fireEvent("new-router-created");
    } catch (e) {
      console.log("An error occured during router creation");
      console.log(e);
    }
  }

  createRoute<LayoutProps, RoutePathParamKeys extends string = "">(
    routeParameters: RouteParameters<AppState, LayoutProps>
  ): Route<RoutePathParamKeys, AppState, LayoutProps> {
    const {
      path,
      view,
      isVisible = true,
      isOverlayChild = true,
      isRenderingAllChildren = false,
    } = routeParameters;
    const route = new Route<RoutePathParamKeys, AppState, LayoutProps>(
      path,
      view,
      isVisible,
      isOverlayChild,
      isRenderingAllChildren
    );

    // recreate router when single routes change
    route.on("change", () => this.buildRouter());

    this.routes.push(route);
    this.buildRouter();
    return route;
  }

  createOverlayView<LayoutProps>({
    name,
    layout = new DefaultLayout() as Layout<LayoutProps>,
  }: {
    name: string;
    layout?: Layout<LayoutProps>;
  }): View<AppState, LayoutProps> {
    this.overlay = new View<AppState, LayoutProps>(name, layout);
    return this.overlay as View<AppState, LayoutProps>;
  }

  navigate(path: string, options?: NavigateOptions) {
    /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
    this.router.navigate(path, options);
    this.fireEvent("navigated-to-path", { path });
  }
}
