import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

// eslint-disable-next-line
export type CRQQuantificationDialogProps = {};
export type CRQQuantificationDialogEvent = "cancel" | "submit";

export const CRQQuantificationDialog: PimoReactComponent<
  CRQQuantificationDialogProps,
  CRQQuantificationDialogEvent
> = ({ fireEvent }) => {
  const onClose = () => {
    fireEvent?.("cancel");
  };
  const onSubmit = () => {
    fireEvent?.("submit");
  };

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{ margin: 8 }}
      PaperProps={{ sx: { borderRadius: 5 } }}
    >
      <DialogTitle>Run additional quantification?</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          You are about to run an additional CRQ model run. Note that there are
          already monthly automatically triggered CRQ model runs on the 25th of
          each month. When you click “Continue”, a new quantification run will
          be triggered and the results of the run will be displayed in the
          platform after a certain period of time.
        </Typography>
        <br />
        <Typography sx={{ fontWeight: "bold" }}>
          If you only want to perform a test run and not a productive run,
          please contact <a href="mailto:isrm@allianz.com">isrm@allianz.com</a>
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",

          m: 2,
          gap: 2,
        }}
      >
        <Button type="button" onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button type="button" onClick={onSubmit} variant="contained">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
