import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";

export type CRQQuestionnaireDialogType =
  | "cancel-with-changes"
  | "submit"
  | "submit-without-changes";
export type CRQQuestionnaireDialogProps = {
  type?: CRQQuestionnaireDialogType;
};
export type CRQQuestionnaireDialogEvent = "close" | "continue";

const CRQ_QUESTIONNAIRE_DIALOG_TEXTS = {
  "cancel-with-changes": {
    title: "Close without saving?",
    content:
      "If you close the dialog without saving, all of your changes will be lost.",
  },
  submit: {
    title: "Thank you for the update.",
    content:
      "Your changes have been successfully saved. The updated input will be used in the next quantification run. The date for the last questionnaire update will be updated.",
  },
  "submit-without-changes": {
    title: "Your data has been saved.",
    content:
      "No changes have been done, therefore the date for the last questionnaire update will not be adapted.",
  },
} satisfies Record<
  CRQQuestionnaireDialogType,
  { content: string; title: string }
>;

export const CRQQuestionnaireDialog: PimoReactComponent<
  CRQQuestionnaireDialogProps,
  CRQQuestionnaireDialogEvent
> = ({ fireEvent, type }) => {
  const onClose = () => {
    fireEvent?.("close");
  };
  const onContinue = () => {
    fireEvent?.("continue");
  };

  if (!type) {
    return null;
  }

  const { title, content } = CRQ_QUESTIONNAIRE_DIALOG_TEXTS[type];

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{ margin: 8 }}
      PaperProps={{ sx: { borderRadius: 5 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>{content}</DialogContent>
      <Divider />
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",

          m: 2,
          gap: 2,
        }}
      >
        <Button type="button" onClick={onClose} variant="contained">
          Close
        </Button>
        <Button type="button" onClick={onContinue} variant="text">
          Continue Editing
        </Button>
      </DialogActions>
    </Dialog>
  );
};
