import { TrendingDown, TrendingFlat, TrendingUp } from "@mui/icons-material";
import { calculateEffect } from "crq-utils";
import type { FC } from "react";

export type CRQEffectTrendingProps = {
  dividend: number;
  divisor: number;
};

export const CRQEffectTrending: FC<CRQEffectTrendingProps> = ({
  dividend,
  divisor,
}) => {
  const effect = calculateEffect({
    dividend,
    divisor,
  });

  switch (true) {
    case typeof effect === "number" && effect < 0:
      return <TrendingDown sx={{ color: "#979797" }} />;
    case typeof effect === "number" && effect > 0:
      return <TrendingUp sx={{ color: "#979797" }} />;
    case effect === 0:
    case effect === "n/a":
    default:
      return <TrendingFlat sx={{ color: "#979797" }} />;
  }
};
