import { CheckBoxFilter, DropdownFilter } from "@pimo/pimo-components";
import { Close } from "@mui/icons-material";
import { FilterAlt } from "@mui/icons-material";
import { Box, Divider, FormControl, Typography, useTheme } from "@mui/material";
import type { FilterData, KovrrFQStatus, UpdateStatus } from "crq-types";
import type { FC } from "react";

export type FilterDialogProps = {
  names: string[];
  onClick?: () => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    trigger: string
  ) => void;
  filterData: FilterData;
};

const UPDATE_STATUS_FILTER_OPTIONS = [
  { label: "Up to date", value: "up-to-date" },
  { label: "To be updated", value: "to-be-updated" },
  { label: "Outdated", value: "outdated" },
] as const satisfies {
  label: string;
  value: UpdateStatus;
}[];

const RUN_STATUS_FILTER_OPTIONS = [
  { label: "Success", value: "Success" },
  { label: "Failed", value: "Failed" },
] as const satisfies {
  label: KovrrFQStatus;
  value: KovrrFQStatus;
}[];

export const FilterDialog: FC<FilterDialogProps> = ({
  names,
  onClick,
  handleChange,
  filterData,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 1,
        gap: 0,
        width: 300,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 1,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            pr: 1,
          }}
        >
          <FilterAlt
            sx={{ color: theme.palette.primary.main, height: "35px" }}
          />
          <Typography sx={{ fontWeight: 500 }}>Filter</Typography>
        </Box>
        <Close onClick={onClick} />
      </Box>
      <FormControl
        component="fieldset"
        sx={{ display: "flex", flexDirection: "column", gap: 1 }}
      >
        <Divider />
        <CheckBoxFilter
          sectionTitle="Status Questionnaire"
          options={UPDATE_STATUS_FILTER_OPTIONS}
          triggerIdentifier="status"
          handleChange={handleChange}
          currentValues={filterData.status}
        />
        <Divider />
        <CheckBoxFilter
          sectionTitle="Status Last CRQ Run"
          options={RUN_STATUS_FILTER_OPTIONS}
          triggerIdentifier="runs"
          handleChange={handleChange}
          currentValues={filterData.runs}
        />
        <Divider />
        <DropdownFilter
          options={names}
          value={filterData.names}
          label="OE name"
          handleChange={handleChange}
          triggerIdentifier="names"
        />
      </FormControl>
    </Box>
  );
};
