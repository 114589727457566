import {
  DefaultOverlay,
  DefaultOverlayEventNames,
  ReportingDatePopup,
  type DefaultOverlayProps,
} from "@pimo/pimo-components";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  EventOutlined,
  FileDownloadOutlined,
  Logout,
} from "@mui/icons-material";
import { getReportingDateForCalendar } from "crq-utils";
import type { Profile } from "crq-types";
import { useState } from "react";

import { STRAPI_URL } from "../../env";
import { Box, Button } from "@mui/material";

export type CRQOverlayProps = DefaultOverlayProps & {
  reportingDate?: string;
  userProfile?: Profile;
};

export type CRQOverlayEventNames =
  | DefaultOverlayEventNames
  | "overlay:toggle-date-picker";

type CRQOverlayEventPayload = { reportingDate: string };

export const CRQOverlay: PimoReactComponent<
  CRQOverlayProps,
  CRQOverlayEventNames,
  CRQOverlayEventPayload
> = ({ fireEvent, ...props }) => {
  const [openReportingDatePopup, setOpenReportingDatePopup] = useState(false);

  const headerProps: DefaultOverlayProps["header"] = {
    ...props.header,
    children: (
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          onClick={() =>
            (location.href = "mailto:isrm@allianz.com?subject=CRQ:")
          }
          sx={{
            background: "#E5A537",
            color: "white",
            px: 2,
            textTransform: "none",
            ":hover": { background: "#E5A537", opacity: 0.75 },
          }}
        >
          Need Help?
        </Button>
        <ReportingDatePopup
          isOpen={openReportingDatePopup}
          setOpen={setOpenReportingDatePopup}
          reportingDate={props.reportingDate ?? ""}
          reportingCycleDates={getReportingDateForCalendar()}
          onSubmit={(value) =>
            fireEvent?.(
              "overlay:toggle-date-picker",
              value as CRQOverlayEventPayload
            )
          }
        />
      </Box>
    ),
    entries: [
      ...(props?.userProfile?.role === "admin"
        ? [
            {
              icon: EventOutlined,
              onClick: () => setOpenReportingDatePopup(true),
              text: "Set Update Period",
            },
          ]
        : []),
      ...(props?.userProfile?.role === "admin"
        ? [
            {
              text: "Download Excel Report",
              icon: FileDownloadOutlined,
              onClick: () => window.open(`${STRAPI_URL}/api/export`),
            },
          ]
        : []),
      {
        icon: Logout,
        onClick: () => fireEvent?.("overlay:logout"),
        text: "Logout",
      },
    ],
  };
  return (
    <DefaultOverlay {...props} fireEvent={fireEvent} header={headerProps} />
  );
};
