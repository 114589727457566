import { InfoOutlined } from "@mui/icons-material";
import { IconButton, Popover, Typography } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  Markdown,
  RadialBarChartCard,
  RadialBarChartCardProps,
} from "@pimo/pimo-components";
import { useState } from "react";

type CRQRadialBarChartProps = { infoText?: string } & RadialBarChartCardProps;

export const CRQRadialBarChart: PimoReactComponent<CRQRadialBarChartProps> = ({
  infoText,
  ...props
}: CRQRadialBarChartProps) => {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null
  );

  return (
    <>
      <RadialBarChartCard
        {...props}
        cardProps={{
          rightSlot: infoText ? (
            <IconButton
              onClick={(e) => setPopoverAnchor(e.target as HTMLButtonElement)}
            >
              <InfoOutlined />
            </IconButton>
          ) : undefined,
        }}
      />

      {infoText && (
        <Popover
          open={!!popoverAnchor}
          anchorEl={popoverAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => setPopoverAnchor(null)}
        >
          <Typography component="div" sx={{ maxWidth: "500px", px: 2 }}>
            <Markdown>{infoText}</Markdown>
          </Typography>
        </Popover>
      )}
    </>
  );
};
