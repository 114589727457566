import { Box } from "@mui/material";
import { LineChart, type LineChartProps } from "@mui/x-charts/LineChart";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import type { PropsWithChildren } from "react";

import { Card, CardContent, type CardProps } from "../../molecules";

export type MuiLineChartCardProps = Omit<LineChartProps, "series"> & {
  /** additional card props */
  cardProps?: CardProps;
  /** card title */
  title: string;
  series: {
    /** some id to differentiate the series */
    id: string;
    /** series label */
    label: string;
    /** series values */
    data: (number | null)[];
    /** the series' color */
    color?: string;
    /** creates a line even though no value is available */
    connectNulls?: boolean;
    /** curve type to be displayed */
    curve?: "linear" | "natural";
    /** shows the mark */
    showMark?: (params: { index: number }) => boolean;
  }[];
};

export const MuiLineChartCard: PimoReactComponent<
  PropsWithChildren<MuiLineChartCardProps>
> = ({ cardProps = {}, children, title, series, ...props }) => {
  return (
    <Card {...cardProps} data-testid="DonutChart" title={title}>
      <CardContent>
        {children}

        <Box>
          <LineChart
            {...props}
            height={350}
            margin={{ bottom: 100, top: -10 }}
            series={series}
            slotProps={{
              legend: {
                position: { vertical: "bottom", horizontal: "right" },
              },
            }}
            sx={{
              "& .MuiLineElement-series-planned": {
                transform: "translateY(1px)",
              },
              "& .MuiLineElement-series-actual": {
                transform: "translateY(-1px)",
              },
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default MuiLineChartCard;

export const MONTHS = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const QUARTERS = ["Q1", "Q2", "Q3", "Q4"];

export function getAllYearsBetweenDates(
  startYear: number,
  endYear: number
): number[] {
  const years: number[] = [];

  while (startYear <= endYear) {
    years.push(startYear);
    startYear++;
  }

  return years;
}
