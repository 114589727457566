import { LoginBox } from "../components/login-box";
import { App, UniversalAppState } from "./app";
import { Cache } from "./cache";
import { EventEmitter } from "./event-emitter";
import { Plugin } from "./plugin";

export interface LoginPluginPartialAppState<UserProfile> {
  isLoggedIn: boolean;
  userProfile?: UserProfile;
}

const DEFAULT_INTERVAL_TO_CHECK_FOR_ENDED_SESSIONS = 30_000;

/**
 * Plugin that manages logging in with our [Strapi Keycloak plugin](https://gitlab.com/hipsquare/strapi-plugin-keycloak).
 *
 * It enforces that the user is logged in. It creates a /login route with a login button
 * and recirects logged-out users there.
 *
 * When clicking the login button, the login process is initialized.
 *
 * The plugin also populates the `isLoggedIn` and `userProfile` state keys so the app can always get the current login
 * state and profile.
 */
export class LoginPlugin<
    UserProfile,
    AppState extends LoginPluginPartialAppState<UserProfile> &
      UniversalAppState,
  >
  extends EventEmitter<"login-status-changed", { isLoggedIn: boolean }>
  implements Plugin<AppState, LoginPluginPartialAppState<UserProfile>>
{
  private cache: Cache;
  private app?: App<AppState>;
  private initialState: LoginPluginPartialAppState<UserProfile> = {
    isLoggedIn: false,
  };
  detectEndedSessionIntervalHandler?: ReturnType<typeof setInterval>;

  constructor(
    private postLoginPath: string,
    private strapiUrl: string,
    private pageTitle: string,
    private options?: {
      automaticallyDetectEndedSessions?: boolean;
      automaticallyDetectEndedSessionsIntervalMs?: number;
    }
  ) {
    super();
    this.cache = new Cache(
      this.options?.automaticallyDetectEndedSessionsIntervalMs ||
        DEFAULT_INTERVAL_TO_CHECK_FOR_ENDED_SESSIONS
    );
  }

  async onRegister(app: App<AppState>) {
    this.app = app;
    const view = app.createView({ name: "login" });

    const loginBox = view.addComponent({
      component: LoginBox,
    });
    loginBox.mapState(() => ({ title: this.pageTitle }));
    loginBox.on(
      "component:click",
      () =>
        (window.location.href = `${this.strapiUrl}/keycloak/login?redirectTo=${window.location.origin}/callback`) as unknown as void
    );

    app.createRoute({
      path: "/login",
      view: view,
      isVisible: true,
      isOverlayChild: false,
    });

    /* eslint-disable-next-line */
    app.on(
      "router:route-loaded",
      async (evt: { payload?: { path?: string } }) => {
        const loggedIn: boolean = await this.isLoggedIn();

        switch (evt.payload?.path) {
          case "/callback": {
            await this.updateLoginState(loggedIn);
            if (loggedIn) {
              this.app?.navigate(this.postLoginPath);
              this.fireEvent("login-status-changed", { isLoggedIn: true });
            }
            break;
          }
          default: {
            if (evt.payload?.path !== "/login" && !loggedIn) {
              this.app?.navigate("/login");
              this.app?.setAppState({
                ...this.app?.getAppState(),
                isLoading: false,
              });
            } else if (evt.payload?.path === "/login" && loggedIn) {
              this.app?.navigate("/");
            } else if (evt.payload?.path === "/login" && !loggedIn) {
              this.app?.setAppState({
                ...this.app.getAppState(),
                isLoading: false,
              });
            }
          }
        }
      }
    );

    const callbackView = app.createView({ name: "callback" });

    app.createRoute({
      path: "/callback",
      view: callbackView,
      isVisible: true,
      isOverlayChild: false,
    });

    // load login status when app is initially registered
    const loggedIn = await this.isLoggedIn();

    if (loggedIn) {
      this.setupIntervalToDetectEndedSessions();
    }

    await this.updateLoginState(loggedIn);

    if (loggedIn) {
      this.fireEvent("login-status-changed", { isLoggedIn: true });
    }

    // redirect to login page if user is not logged in
    if (!loggedIn) {
      this.app.navigate("/login");
    }
  }

  private setupIntervalToDetectEndedSessions() {
    if (!this.options?.automaticallyDetectEndedSessions) {
      return;
    }

    this.detectEndedSessionIntervalHandler = setInterval(() => {
      void (async () => {
        const loggedIn = await this.isLoggedIn();
        if (!loggedIn) {
          this.app?.navigate("/login");
          this.destroyIntervalToDetectEndedSessions();
        }
      })();
    }, this.options.automaticallyDetectEndedSessionsIntervalMs || DEFAULT_INTERVAL_TO_CHECK_FOR_ENDED_SESSIONS);
  }

  private destroyIntervalToDetectEndedSessions() {
    if (this.detectEndedSessionIntervalHandler) {
      clearInterval(this.detectEndedSessionIntervalHandler);
    }
  }

  getInitialState() {
    return this.initialState;
  }

  async isLoggedIn(): Promise<boolean> {
    try {
      const response = await this.cache.fetch(
        `${this.strapiUrl}/keycloak/isLoggedIn`,
        {
          credentials: "include",
        }
      );

      return (await response.json()) as Promise<boolean>;
    } catch {
      return false;
    }
  }

  private async updateLoginState(loggedIn: boolean) {
    let userProfile;
    if (loggedIn) {
      userProfile = await this.getUserProfile();
    }

    this.app?.setAppState({
      ...this.app.getAppState(),
      isLoggedIn: loggedIn,
      userProfile,
    });
  }

  private async getUserProfile(): Promise<unknown> {
    const response = await this.cache.fetch(
      `${this.strapiUrl}/keycloak/profile`,
      {
        credentials: "include",
      }
    );

    return (await response.json()) as Promise<unknown>;
  }

  logout() {
    window.location.href = `${this.strapiUrl}/keycloak/logout`;
  }
}
