var CIS_CONTROL_RATINGS = [
    "Not Implemented",
    "IG1",
    "IG2",
    "IG3",
];

var FILTER_PARAMS = [
    "date",
    "names",
    "runs",
    "search",
    "status",
];

var RATES_2023 = {
    AUD: 0.637,
    BGN: 0.511,
    BRL: 0.176,
    CAD: 0.69,
    CHF: 1.014,
    CNY: 0.136,
    EUR: 1,
    GBP: 1.128,
    HRK: 0.13,
    HUF: 0.002,
    IDR: 0.00006,
    JPY: 0.007,
    MXN: 0.048,
    MYR: 0.213,
    PHP: 0.017,
    PLN: 0.214,
    RON: 0.202,
    RUB: 0.008,
    SGD: 0.699,
    THB: 0.027,
    TRY: 0.05,
    USD: 0.937,
};
var RATES_2024 = {
    AUD: 0.618,
    BGN: 0.511,
    BRL: 0.187,
    CAD: 0.686,
    CHF: 1.076,
    CNY: 0.128,
    EUR: 1,
    GBP: 1.153,
    HRK: 0.13,
    HUF: 0.003,
    IDR: 0.00006,
    JPY: 0.006,
    MXN: 0.053,
    MYR: 0.198,
    PHP: 0.016,
    PLN: 0.229,
    RON: 0.201,
    RUB: 0.01,
    SGD: 0.687,
    THB: 0.027,
    TRY: 0.031,
    USD: 0.911,
};
/**
 * Kovrr's conversion rates are based off of the European Central Bank.
 * The conversion rates are based off of what the currency is worth in Euro.
 * So for example, 1 USD is worth 0.937 Euro.
 *
 * Resoures:
 * - https://data.ecb.europa.eu/currency-converter
 * - https://www.oanda.com/currency-converter
 */
var CONVERSION_RATES = {
    "2023-01-01": RATES_2023,
    "2024-01-01": RATES_2024,
    latest: RATES_2024,
};

var COUNTRIES_OF_OPERATION = [
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia, Plurinational State of",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, the Democratic Republic of the",
    "Cook Islands",
    "Costa Rica",
    "Côte d'Ivoire",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic of",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic of",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia, the Former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Réunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan, Province of China",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela, Bolivarian Republic of",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.S.",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
];

var CURRENCIES = [
    "USD",
    "EUR",
    "AUD",
    "BRL",
    "GBP",
    "BGN",
    "CAD",
    "CNY",
    "HRK",
    "HUF",
    "IDR",
    "JPY",
    "MYR",
    "MXN",
    "PHP",
    "PLN",
    "RON",
    "RUB",
    "SGD",
    "CHF",
    "THB",
    "TRY",
];

var INDUSTRIES_OF_OPERATION = [
    "Insurance Carriers",
    "Insurance Agents, Brokers and Service",
    "Depository Institutions",
    "Nondepository Credit Institutions",
    "Security & Commodity Brokers, Dealers, Exchanges & Services",
    "Holding and Other Investment Offices",
    "Real Estate",
    "Business Services",
    "Personal Services",
    "Agriculture, Forestry And Fishing",
    "Metal Mining",
    "Coal Mining",
    "Oil and Gas Extraction",
    "Mining and Quarrying of Nonmetallic Minerals, Except Fuels",
    "Construction",
    "Food and Kindred Products",
    "Tobacco Products",
    "Textile Mill Products",
    "Apparel, Finished Products from Fabrics & Similar Materials",
    "Lumber and Wood Products, Except Furniture",
    "Furniture and Fixtures",
    "Paper and Allied Products",
    "Printing, Publishing and Allied Industries",
    "Chemicals and Allied Products",
    "Petroleum Refining and Related Industries",
    "Rubber and Miscellaneous Plastic Products",
    "Leather and Leather Products",
    "Stone, Clay, Glass, and Concrete Products",
    "Primary Metal Industries",
    "Fabricated Metal Products",
    "Industrial and Commercial Machinery and Computer Equipment",
    "Electronic & Other Electrical Equipment & Components",
    "Transportation Equipment",
    "Measuring, Photographic, Medical, & Optical Goods, & Clocks",
    "Miscellaneous Manufacturing Industries",
    "Railroad Transportation",
    "Local & Suburban Transit & Interurban Highway Transportation",
    "Motor Freight Transportation",
    "United States Postal Service",
    "Water Transportation",
    "Transportation by Air",
    "Pipelines, Except Natural Gas",
    "Transportation Services",
    "Communications",
    "Electric, Gas and Sanitary Services",
    "Wholesale Trade - Durable Goods",
    "Wholesale Trade - Nondurable Goods",
    "Building Materials, Hardware, Garden Supplies & Mobile Homes",
    "General Merchandise Stores",
    "Food Stores",
    "Automotive Dealers and Gasoline Service Stations",
    "Apparel and Accessory Stores",
    "Home Furniture, Furnishings and Equipment Stores",
    "Eating and Drinking Places",
    "Miscellaneous Retail",
    "Hotels, Rooming Houses, Camps, and Other Lodging Places",
    "Advertising",
    "Consumer Credit Reporting Agencies, Mercantile",
    "Mailing, Reproduction, Commercial Art And Photography, and Stenographic Services",
    "Services To Dwellings And Other Buildings",
    "Miscellaneous Equipment Rental And Leasing",
    "Personnel Supply Services",
    "Computer Programming, Data Processing, And Other Computer-Related Services",
    "Miscellaneous Business Services",
    "Computer Programming Services",
    "Prepackaged Software",
    "Computer Integrated Systems Design",
    "Computer Processing and Data Preparation and Processing Services",
    "Automotive Repair, Services and Parking",
    "Miscellaneous Repair Services",
    "Motion Pictures",
    "Amusement and Recreation Services",
    "Health Services",
    "Hospitals",
    "Nursing And Personal Care Facilities",
    "Offices And Clinics",
    "Medical And Dental Laboratories",
    "Legal Services",
    "Educational Services",
    "Social Services",
    "Museums, Art Galleries and Botanical and Zoological Gardens",
    "Membership Organizations",
    "Engineering, Accounting, Research, and Management Services",
    "Private Households",
    "Services, Not Elsewhere Classified",
    "Executive, Legislative & General Government, Except Finance",
    "Justice, Public Order and Safety",
    "Public Finance, Taxation and Monetary Policy",
    "Administration of Human Resource Programs",
    "Administration of Environmental Quality and Housing Programs",
    "Administration of Economic Programs",
    "National Security and International Affairs",
    "Nonclassifiable Establishments",
];

var KOVRR_CERTIFICATES = [
    "SOC",
    "ISO",
    "PCI_DSS",
    "NIST_800",
    "NIST_CSF",
];
var KOVRR_REGULATIONS = [
    "PCI",
    "USFLR",
    "USSLR",
    "GDPR",
    "OTHER",
];

var KOVRR_COUNTRIES_OF_OPERATION = [
    { label: "Afghanistan", value: "AF" },
    { label: "Åland Islands", value: "AX" },
    { label: "Albania", value: "AL" },
    { label: "Algeria", value: "DZ" },
    { label: "American Samoa", value: "AS" },
    { label: "Andorra", value: "AD" },
    { label: "Angola", value: "AO" },
    { label: "Anguilla", value: "AI" },
    { label: "Antarctica", value: "AQ" },
    { label: "Antigua and Barbuda", value: "AG" },
    { label: "Argentina", value: "AR" },
    { label: "Armenia", value: "AM" },
    { label: "Aruba", value: "AW" },
    { label: "Australia", value: "AU" },
    { label: "Austria", value: "AT" },
    { label: "Azerbaijan", value: "AZ" },
    { label: "Bahamas", value: "BS" },
    { label: "Bahrain", value: "BH" },
    { label: "Bangladesh", value: "BD" },
    { label: "Barbados", value: "BB" },
    { label: "Belarus", value: "BY" },
    { label: "Belgium", value: "BE" },
    { label: "Belize", value: "BZ" },
    { label: "Benin", value: "BJ" },
    { label: "Bermuda", value: "BM" },
    { label: "Bhutan", value: "BT" },
    { label: "Bolivia, Plurinational State of", value: "BO" },
    { label: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
    { label: "Bosnia and Herzegovina", value: "BA" },
    { label: "Botswana", value: "BW" },
    { label: "Bouvet Island", value: "BV" },
    { label: "Brazil", value: "BR" },
    { label: "British Indian Ocean Territory", value: "IO" },
    { label: "Brunei Darussalam", value: "BN" },
    { label: "Bulgaria", value: "BG" },
    { label: "Burkina Faso", value: "BF" },
    { label: "Burundi", value: "BI" },
    { label: "Cambodia", value: "KH" },
    { label: "Cameroon", value: "CM" },
    { label: "Canada", value: "CA" },
    { label: "Cape Verde", value: "CV" },
    { label: "Cayman Islands", value: "KY" },
    { label: "Central African Republic", value: "CF" },
    { label: "Chad", value: "TD" },
    { label: "Chile", value: "CL" },
    { label: "China", value: "CN" },
    { label: "Christmas Island", value: "CX" },
    { label: "Cocos (Keeling) Islands", value: "CC" },
    { label: "Colombia", value: "CO" },
    { label: "Comoros", value: "KM" },
    { label: "Congo", value: "CG" },
    { label: "Congo, the Democratic Republic of the", value: "CD" },
    { label: "Cook Islands", value: "CK" },
    { label: "Costa Rica", value: "CR" },
    { label: "Côte d'Ivoire", value: "CI" },
    { label: "Croatia", value: "HR" },
    { label: "Cuba", value: "CU" },
    { label: "Curaçao", value: "CW" },
    { label: "Cyprus", value: "CY" },
    { label: "Czech Republic", value: "CZ" },
    { label: "Denmark", value: "DK" },
    { label: "Djibouti", value: "DJ" },
    { label: "Dominica", value: "DM" },
    { label: "Dominican Republic", value: "DO" },
    { label: "Ecuador", value: "EC" },
    { label: "Egypt", value: "EG" },
    { label: "El Salvador", value: "SV" },
    { label: "Equatorial Guinea", value: "GQ" },
    { label: "Eritrea", value: "ER" },
    { label: "Estonia", value: "EE" },
    { label: "Ethiopia", value: "ET" },
    { label: "Falkland Islands (Malvinas)", value: "FK" },
    { label: "Faroe Islands", value: "FO" },
    { label: "Fiji", value: "FJ" },
    { label: "Finland", value: "FI" },
    { label: "France", value: "FR" },
    { label: "French Guiana", value: "GF" },
    { label: "French Polynesia", value: "PF" },
    { label: "French Southern Territories", value: "TF" },
    { label: "Gabon", value: "GA" },
    { label: "Gambia", value: "GM" },
    { label: "Georgia", value: "GE" },
    { label: "Germany", value: "DE" },
    { label: "Ghana", value: "GH" },
    { label: "Gibraltar", value: "GI" },
    { label: "Greece", value: "GR" },
    { label: "Greenland", value: "GL" },
    { label: "Grenada", value: "GD" },
    { label: "Guadeloupe", value: "GP" },
    { label: "Guam", value: "GU" },
    { label: "Guatemala", value: "GT" },
    { label: "Guernsey", value: "GG" },
    { label: "Guinea", value: "GN" },
    { label: "Guinea-Bissau", value: "GW" },
    { label: "Guyana", value: "GY" },
    { label: "Haiti", value: "HT" },
    { label: "Heard Island and McDonald Islands", value: "HM" },
    { label: "Holy See (Vatican City State)", value: "VA" },
    { label: "Honduras", value: "HN" },
    { label: "Hong Kong", value: "HK" },
    { label: "Hungary", value: "HU" },
    { label: "Iceland", value: "IS" },
    { label: "India", value: "IN" },
    { label: "Indonesia", value: "ID" },
    { label: "Iran, Islamic Republic of", value: "IR" },
    { label: "Iraq", value: "IQ" },
    { label: "Ireland", value: "IE" },
    { label: "Isle of Man", value: "IM" },
    { label: "Israel", value: "IL" },
    { label: "Italy", value: "IT" },
    { label: "Jamaica", value: "JM" },
    { label: "Japan", value: "JP" },
    { label: "Jersey", value: "JE" },
    { label: "Jordan", value: "JO" },
    { label: "Kazakhstan", value: "KZ" },
    { label: "Kenya", value: "KE" },
    { label: "Kiribati", value: "KI" },
    { label: "Korea, Democratic People's Republic of", value: "KP" },
    { label: "Korea, Republic of", value: "KR" },
    { label: "Kuwait", value: "KW" },
    { label: "Kyrgyzstan", value: "KG" },
    { label: "Lao People's Democratic Republic", value: "LA" },
    { label: "Latvia", value: "LV" },
    { label: "Lebanon", value: "LB" },
    { label: "Lesotho", value: "LS" },
    { label: "Liberia", value: "LR" },
    { label: "Libya", value: "LY" },
    { label: "Liechtenstein", value: "LI" },
    { label: "Lithuania", value: "LT" },
    { label: "Luxembourg", value: "LU" },
    { label: "Macao", value: "MO" },
    { label: "Macedonia, the Former Yugoslav Republic of", value: "MK" },
    { label: "Madagascar", value: "MG" },
    { label: "Malawi", value: "MW" },
    { label: "Malaysia", value: "MY" },
    { label: "Maldives", value: "MV" },
    { label: "Mali", value: "ML" },
    { label: "Malta", value: "MT" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Martinique", value: "MQ" },
    { label: "Mauritania", value: "MR" },
    { label: "Mauritius", value: "MU" },
    { label: "Mayotte", value: "YT" },
    { label: "Mexico", value: "MX" },
    { label: "Micronesia, Federated States of", value: "FM" },
    { label: "Moldova, Republic of", value: "MD" },
    { label: "Monaco", value: "MC" },
    { label: "Mongolia", value: "MN" },
    { label: "Montenegro", value: "ME" },
    { label: "Montserrat", value: "MS" },
    { label: "Morocco", value: "MA" },
    { label: "Mozambique", value: "MZ" },
    { label: "Myanmar", value: "MM" },
    { label: "Namibia", value: "NA" },
    { label: "Nauru", value: "NR" },
    { label: "Nepal", value: "NP" },
    { label: "Netherlands", value: "NL" },
    { label: "New Caledonia", value: "NC" },
    { label: "New Zealand", value: "NZ" },
    { label: "Nicaragua", value: "NI" },
    { label: "Niger", value: "NE" },
    { label: "Nigeria", value: "NG" },
    { label: "Niue", value: "NU" },
    { label: "Norfolk Island", value: "NF" },
    { label: "Northern Mariana Islands", value: "MP" },
    { label: "Norway", value: "NO" },
    { label: "Oman", value: "OM" },
    { label: "Pakistan", value: "PK" },
    { label: "Palau", value: "PW" },
    { label: "Palestine, State of", value: "PS" },
    { label: "Panama", value: "PA" },
    { label: "Papua New Guinea", value: "PG" },
    { label: "Paraguay", value: "PY" },
    { label: "Peru", value: "PE" },
    { label: "Philippines", value: "PH" },
    { label: "Pitcairn", value: "PN" },
    { label: "Poland", value: "PL" },
    { label: "Portugal", value: "PT" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Qatar", value: "QA" },
    { label: "Réunion", value: "RE" },
    { label: "Romania", value: "RO" },
    { label: "Russian Federation", value: "RU" },
    { label: "Rwanda", value: "RW" },
    { label: "Saint Barthélemy", value: "BL" },
    { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
    { label: "Saint Kitts and Nevis", value: "KN" },
    { label: "Saint Lucia", value: "LC" },
    { label: "Saint Martin (French part)", value: "MF" },
    { label: "Saint Pierre and Miquelon", value: "PM" },
    { label: "Saint Vincent and the Grenadines", value: "VC" },
    { label: "Samoa", value: "WS" },
    { label: "San Marino", value: "SM" },
    { label: "Sao Tome and Principe", value: "ST" },
    { label: "Saudi Arabia", value: "SA" },
    { label: "Senegal", value: "SN" },
    { label: "Serbia", value: "RS" },
    { label: "Seychelles", value: "SC" },
    { label: "Sierra Leone", value: "SL" },
    { label: "Singapore", value: "SG" },
    { label: "Sint Maarten (Dutch part)", value: "SX" },
    { label: "Slovakia", value: "SK" },
    { label: "Slovenia", value: "SI" },
    { label: "Solomon Islands", value: "SB" },
    { label: "Somalia", value: "SO" },
    { label: "South Africa", value: "ZA" },
    { label: "South Georgia and the South Sandwich Islands", value: "GS" },
    { label: "South Sudan", value: "SS" },
    { label: "Spain", value: "ES" },
    { label: "Sri Lanka", value: "LK" },
    { label: "Sudan", value: "SD" },
    { label: "Suriname", value: "SR" },
    { label: "Svalbard and Jan Mayen", value: "SJ" },
    { label: "Swaziland", value: "SZ" },
    { label: "Sweden", value: "SE" },
    { label: "Switzerland", value: "CH" },
    { label: "Syrian Arab Republic", value: "SY" },
    { label: "Taiwan, Province of China", value: "TW" },
    { label: "Tajikistan", value: "TJ" },
    { label: "Tanzania, United Republic of", value: "TZ" },
    { label: "Thailand", value: "TH" },
    { label: "Timor-Leste", value: "TL" },
    { label: "Togo", value: "TG" },
    { label: "Tokelau", value: "TK" },
    { label: "Tonga", value: "TO" },
    { label: "Trinidad and Tobago", value: "TT" },
    { label: "Tunisia", value: "TN" },
    { label: "Turkey", value: "TR" },
    { label: "Turkmenistan", value: "TM" },
    { label: "Turks and Caicos Islands", value: "TC" },
    { label: "Tuvalu", value: "TV" },
    { label: "Uganda", value: "UG" },
    { label: "Ukraine", value: "UA" },
    { label: "United Arab Emirates", value: "AE" },
    { label: "United Kingdom", value: "GB" },
    { label: "United States", value: "US" },
    { label: "United States Minor Outlying Islands", value: "UM" },
    { label: "Uruguay", value: "UY" },
    { label: "Uzbekistan", value: "UZ" },
    { label: "Vanuatu", value: "VU" },
    { label: "Venezuela, Bolivarian Republic of", value: "VE" },
    { label: "Viet Nam", value: "VN" },
    { label: "Virgin Islands, British", value: "VG" },
    { label: "Virgin Islands, U.S.", value: "VI" },
    { label: "Wallis and Futuna", value: "WF" },
    { label: "Western Sahara", value: "EH" },
    { label: "Yemen", value: "YE" },
    { label: "Zambia", value: "ZM" },
    { label: "Zimbabwe", value: "ZW" },
];

var KOVRR_INDUSTIRES_OF_OPERATION = [
    { value: "01", label: "Agriculture, Forestry And Fishing" },
    { value: "10", label: "Metal Mining" },
    { value: "12", label: "Coal Mining" },
    { value: "13", label: "Oil and Gas Extraction" },
    {
        value: "14",
        label: "Mining and Quarrying of Nonmetallic Minerals, Except Fuels",
    },
    {
        value: "17",
        label: "Construction",
    },
    { value: "20", label: "Food and Kindred Products" },
    { value: "21", label: "Tobacco Products" },
    { value: "22", label: "Textile Mill Products" },
    {
        value: "23",
        label: "Apparel, Finished Products from Fabrics & Similar Materials",
    },
    { value: "24", label: "Lumber and Wood Products, Except Furniture" },
    { value: "25", label: "Furniture and Fixtures" },
    { value: "26", label: "Paper and Allied Products" },
    { value: "27", label: "Printing, Publishing and Allied Industries" },
    { value: "28", label: "Chemicals and Allied Products" },
    { value: "29", label: "Petroleum Refining and Related Industries" },
    { value: "30", label: "Rubber and Miscellaneous Plastic Products" },
    { value: "31", label: "Leather and Leather Products" },
    { value: "32", label: "Stone, Clay, Glass, and Concrete Products" },
    { value: "33", label: "Primary Metal Industries" },
    { value: "34", label: "Fabricated Metal Products" },
    {
        value: "35",
        label: "Industrial and Commercial Machinery and Computer Equipment",
    },
    {
        value: "36",
        label: "Electronic & Other Electrical Equipment & Components",
    },
    { value: "37", label: "Transportation Equipment" },
    {
        value: "38",
        label: "Measuring, Photographic, Medical, & Optical Goods, & Clocks",
    },
    { value: "39", label: "Miscellaneous Manufacturing Industries" },
    { value: "40", label: "Railroad Transportation" },
    {
        value: "41",
        label: "Local & Suburban Transit & Interurban Highway Transportation",
    },
    { value: "42", label: "Motor Freight Transportation" },
    { value: "43", label: "United States Postal Service" },
    { value: "44", label: "Water Transportation" },
    { value: "45", label: "Transportation by Air" },
    { value: "46", label: "Pipelines, Except Natural Gas" },
    { value: "47", label: "Transportation Services" },
    { value: "48", label: "Communications" },
    { value: "49", label: "Electric, Gas and Sanitary Services" },
    { value: "50", label: "Wholesale Trade - Durable Goods" },
    { value: "51", label: "Wholesale Trade - Nondurable Goods" },
    {
        value: "52",
        label: "Building Materials, Hardware, Garden Supplies & Mobile Homes",
    },
    { value: "53", label: "General Merchandise Stores" },
    { value: "54", label: "Food Stores" },
    { value: "55", label: "Automotive Dealers and Gasoline Service Stations" },
    { value: "56", label: "Apparel and Accessory Stores" },
    { value: "57", label: "Home Furniture, Furnishings and Equipment Stores" },
    { value: "58", label: "Eating and Drinking Places" },
    { value: "59", label: "Miscellaneous Retail" },
    { value: "60", label: "Depository Institutions" },
    { value: "61", label: "Nondepository Credit Institutions" },
    {
        value: "62",
        label: "Security & Commodity Brokers, Dealers, Exchanges & Services",
    },
    { value: "63", label: "Insurance Carriers" },
    { value: "64", label: "Insurance Agents, Brokers and Service" },
    { value: "65", label: "Real Estate" },
    { value: "67", label: "Holding and Other Investment Offices" },
    {
        value: "70",
        label: "Hotels, Rooming Houses, Camps, and Other Lodging Places",
    },
    { value: "72", label: "Personal Services" },
    { value: "73", label: "Business Services" },
    { value: "73-10", label: "Advertising" },
    { value: "73-20", label: "Consumer Credit Reporting Agencies, Mercantile" },
    {
        value: "73-30",
        label: "Mailing, Reproduction, Commercial Art And Photography, and Stenographic Services",
    },
    { value: "73-40", label: "Services To Dwellings And Other Buildings" },
    { value: "73-50", label: "Miscellaneous Equipment Rental And Leasing" },
    { value: "73-60", label: "Personnel Supply Services" },
    {
        value: "73-70",
        label: "Computer Programming, Data Processing, And Other Computer-Related Services",
    },
    { value: "73-80", label: "Miscellaneous Business Services" },
    { value: "73-71", label: "Computer Programming Services" },
    { value: "73-72", label: "Prepackaged Software" },
    { value: "73-73", label: "Computer Integrated Systems Design" },
    {
        value: "73-74",
        label: "Computer Processing and Data Preparation and Processing Services",
    },
    { value: "75", label: "Automotive Repair, Services and Parking" },
    { value: "76", label: "Miscellaneous Repair Services" },
    { value: "78", label: "Motion Pictures" },
    { value: "79", label: "Amusement and Recreation Services" },
    { value: "80", label: "Health Services" },
    { value: "80-60", label: "Hospitals" },
    { value: "80-50", label: "Nursing And Personal Care Facilities" },
    { value: "80-40", label: "Offices And Clinics" },
    { value: "80-70", label: "Medical And Dental Laboratories" },
    { value: "81", label: "Legal Services" },
    { value: "82", label: "Educational Services" },
    { value: "83", label: "Social Services" },
    {
        value: "84",
        label: "Museums, Art Galleries and Botanical and Zoological Gardens",
    },
    { value: "86", label: "Membership Organizations" },
    {
        value: "87",
        label: "Engineering, Accounting, Research, and Management Services",
    },
    { value: "88", label: "Private Households" },
    { value: "89", label: "Services, Not Elsewhere Classified" },
    {
        value: "91",
        label: "Executive, Legislative & General Government, Except Finance",
    },
    { value: "92", label: "Justice, Public Order and Safety" },
    { value: "93", label: "Public Finance, Taxation and Monetary Policy" },
    { value: "94", label: "Administration of Human Resource Programs" },
    {
        value: "95",
        label: "Administration of Environmental Quality and Housing Programs",
    },
    { value: "96", label: "Administration of Economic Programs" },
    { value: "97", label: "National Security and International Affairs" },
    { value: "99", label: "Nonclassifiable Establishments" },
];

var _a, _b;
var KOVRR_RECOMMENDED_ACTION_MAP = {
    // Basic Controls
    ICHA: {
        abbr: "CIS Control 1",
        name: "Inventory and Control of Hardware Assets",
    },
    ICSA: {
        abbr: "CIS Control 2",
        name: "Inventory and Control of Software Assets",
    },
    CVM: {
        abbr: "CIS Control 3",
        name: "Continuous Vulnerability Management",
    },
    CUAP: {
        abbr: "CIS Control 4",
        name: "Controlled Use of Administrative Privileges",
    },
    SCHS: {
        abbr: "CIS Control 5",
        name: "Secure Configuration for Hardware and Software on Mobile Devices, Laptops, Workstations, and Servers",
    },
    MMAAL: {
        abbr: "CIS Control 6",
        name: "Maintenance, Monitoring, and Analysis of Audit Logs",
    },
    // Foundational Controls
    EWBP: {
        abbr: "CIS Control 7",
        name: "Email and Web Browser Protections",
    },
    MD: {
        abbr: "CIS Control 8",
        name: "Malware Defenses",
    },
    LCNPPS: {
        abbr: "CIS Control 9",
        name: "Limitation and Control of Network Ports, Protocols, and Services",
    },
    DRC: {
        abbr: "CIS Control 10",
        name: "Data Recovery Capabilities",
    },
    SCND: {
        abbr: "CIS Control 11",
        name: "Secure Configuration for Network Devices, such as Firewalls, Routers and Switches",
    },
    BD: {
        abbr: "CIS Control 12",
        name: "Boundary Defense",
    },
    DP: {
        abbr: "CIS Control 13",
        name: "Data Protection",
    },
    CAB: {
        abbr: "CIS Control 14",
        name: "Controlled Access Based on the Need to Know",
    },
    WAC: {
        abbr: "CIS Control 15",
        name: "Wireless Access Control",
    },
    AMC: {
        abbr: "CIS Control 16",
        name: "Account Monitoring and Control",
    },
    // Organizational Controls
    ISA: {
        abbr: "CIS Control 17",
        name: "Implement a Security Awareness and Training Program",
    },
    ASS: {
        abbr: "CIS Control 18",
        name: "Application Software Security",
    },
    IRM: {
        abbr: "CIS Control 19",
        name: "Incident Response and Management",
    },
    PTRT: {
        abbr: "CIS Control 20",
        name: "Penetration Tests and Red Team Exercises",
    },
};
var KOVRR_RECOMMENDED_ACTION_LEVEL_MAP = (_a = {},
    _a[-1] = "Not Implemented",
    _a[0] = "I Don't Know",
    _a[1] = "IG1",
    _a[2] = "IG2",
    _a[3] = "IG3",
    _a);
var KOVRR_RECOMMENDED_ACTION_LEVEL_COLOR_MAP = (_b = {},
    _b[-1] = "#FF2323",
    _b[0] = "#95A6B2",
    _b[1] = "#FF802E",
    _b[2] = "#FBBC09",
    _b[3] = "#0DC783",
    _b);

var KOVRR_US_STATES_OF_OPERATION = [
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "District of Columbia", value: "DC" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virginia", value: "VA" },
    { label: "Virgin Islands", value: "VI" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
];

var NUMBER_OF_EMPLOYEES = [
    "e< 10",
    "e10 - 50",
    "e50 - 100",
    "e100 - 500",
    "e500 - 1,000",
    "e1,000 - 5,000",
    "e5,000 - 10,000",
    "e10,000 - 50,000",
    "e50,000 - 100,000",
    "e100,000 +",
];

var UPDATE_STATUS = [
    "up-to-date",
    "to-be-updated",
    "outdated",
    "unknown",
];

var US_STATES_OF_OPERATION = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Virgin Islands",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
];

export { CIS_CONTROL_RATINGS, CONVERSION_RATES, COUNTRIES_OF_OPERATION, CURRENCIES, FILTER_PARAMS, INDUSTRIES_OF_OPERATION, KOVRR_CERTIFICATES, KOVRR_COUNTRIES_OF_OPERATION, KOVRR_INDUSTIRES_OF_OPERATION, KOVRR_RECOMMENDED_ACTION_LEVEL_COLOR_MAP, KOVRR_RECOMMENDED_ACTION_LEVEL_MAP, KOVRR_RECOMMENDED_ACTION_MAP, KOVRR_REGULATIONS, KOVRR_US_STATES_OF_OPERATION, NUMBER_OF_EMPLOYEES, UPDATE_STATUS, US_STATES_OF_OPERATION };
