import { Event } from "@mui/icons-material";
import { Box, BoxProps, Tooltip, useTheme } from "@mui/material";
import { FC } from "react";

import {
  OverviewTableCell,
  OverviewTableCellBody,
  OverviewTableCellHeader,
} from "../overview-table-cell";

export interface DateCellProps {
  /** cell header */
  header?: string;
  /** date value */
  date: string;
  /** custom icon */
  icon?: string;
  /** size of the icon */
  iconSize?: string;
  /** card props */
  cardProps?: BoxProps;
  /** tooltip content */
  textOnHover?: string;
}

export const DateCell: FC<DateCellProps> = ({
  cardProps = {},
  textOnHover,
  date,
  header,
  icon,
  iconSize,
}) => {
  const theme = useTheme();
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "common.white",
            color: "black",
            border: "1px solid",
            borderColor: theme.palette.secondary.main,
            borderRadius: "6px",
            fontSize: "14px",
            fontWeight: 400,
            p: 1,
          },
        },
      }}
      title={textOnHover}
      disableInteractive
    >
      <OverviewTableCell {...cardProps}>
        {header && <OverviewTableCellHeader>{header}</OverviewTableCellHeader>}
        <OverviewTableCellBody sx={{ height: "auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              height: "1.5em",
            }}
          >
            {icon ? (
              <Box
                component="img"
                src={icon}
                sx={{ pr: 1, width: iconSize, height: iconSize }}
              />
            ) : (
              <Event
                sx={{
                  color: theme.palette.primary.light,
                  pr: 1,
                  width: "2rem",
                }}
              />
            )}
            {date}
          </Box>
        </OverviewTableCellBody>
      </OverviewTableCell>
    </Tooltip>
  );
};
