import { GroupsOutlined } from "@mui/icons-material";
import {
  Box,
  BoxProps,
  Divider,
  IconButton,
  Popover,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import React from "react";

import {
  Card,
  type CardProps,
  ProgressBarWithTitleAndValue,
  type ProgressBarWithTitleAndValueProps,
  StatusIndicator,
  StreetlightStatusIndicator,
} from "../../molecules";
import {
  STATUS_INDICATOR_TYPE,
  STATUS_TYPE,
  STREETLIGHT_STATUS_TYPE,
} from "../../types";

export interface InformationCardProps {
  /** title of the card */
  title: string;
  /** popover icon */
  popoverTrigger?: {
    /** top right icon alt */
    alt?: string;
    /** top right popover trigger icon */
    icon?: string;
  };
  /** list of metric cards */
  metrics?: {
    /** title to be displayed for metric */
    title: string;
    /** metric value */
    value: number | string | STATUS_TYPE;
    /** optional parameter to identify the status indicator type */
    type?: STATUS_INDICATOR_TYPE;
  }[];
  /** progress bar object data */
  progressBars?: ProgressBarWithTitleAndValueProps[];
  /** card styling properties */
  cardProps?: CardProps;
  /** metrics styling properties */
  metricsProps?: BoxProps;
  /** optional component for the second icon i.e Team Card Component */
  teamCard?: React.ReactNode;
}

const TEAM_BUTTON_ID = "team-popover-button";
const INFO_BUTTON_ID = "info-popover-button";

const InfoPopoverButton = styled(IconButton)(({ theme }) => ({
  width: 24,
  height: 24,
  ":hover": {
    background: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
}));

const MetricValue: PimoReactComponent<{
  value: number | string | STATUS_TYPE;
  type?: STATUS_INDICATOR_TYPE;
}> = ({ value, type }) => {
  const theme = useTheme();

  if (type === STATUS_INDICATOR_TYPE.STREETLIGHT) {
    return (
      <StreetlightStatusIndicator
        size={"large"}
        defaultBackgroundColor={theme.palette.secondary.main}
        status={value.toString().toLowerCase() as STREETLIGHT_STATUS_TYPE}
      />
    );
  }

  if (value in STATUS_TYPE) {
    return <StatusIndicator size={"large"} status={value as STATUS_TYPE} />;
  }

  return (
    <Typography
      variant="h5"
      sx={{
        color: theme.palette.primary.main,
      }}
    >
      {value}
    </Typography>
  );
};

export const InformationCard: PimoReactComponent<InformationCardProps> = ({
  cardProps = {},
  metrics = [],
  metricsProps,
  popoverTrigger,
  progressBars,
  teamCard,
  title,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClickOpenPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const hasTeamPopup = isMobile && teamCard;

  return (
    <Card
      {...cardProps}
      data-testid="information-card"
      title={title}
      rightSlot={
        hasTeamPopup && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {popoverTrigger?.icon && (
              <InfoPopoverButton
                {...popoverTrigger}
                id={INFO_BUTTON_ID}
                data-testid={INFO_BUTTON_ID}
                onClick={(e) => {
                  handleClickOpenPopover(e);
                }}
              />
            )}
            {/** show the team icon only on smaller screens */}
            <IconButton
              id={TEAM_BUTTON_ID}
              data-testid={TEAM_BUTTON_ID}
              onClick={(e) => {
                handleClickOpenPopover(e);
              }}
              sx={{
                ml: 1,
              }}
            >
              <GroupsOutlined
                sx={{
                  background: theme.palette.primary.main,
                  color: "white",
                  borderRadius: "100%",
                  width: 28,
                  height: 28,
                  ":hover": {
                    background: theme.palette.primary.light,
                    color: theme.palette.common.white,
                  },
                }}
              />
            </IconButton>
          </Box>
        )
      }
    >
      {metrics?.length > 0 && (
        <>
          <Divider
            sx={{
              bgcolor: theme.palette.secondary.main,
              height: "1px",
              borderWidth: 0,
            }}
          />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: `repeat(${metrics?.length}, 1fr)`,
              gridTemplateRows: `repeat(2, max-content)`,
              gap: "10px",
              p: 2,
              ...metricsProps?.sx,
            }}
          >
            {/* // TODO only loop through `metrics` once  */}
            {metrics?.map((entry, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textAlign: "center",
                    lineHeight: "1.2rem",
                    fontSize: "1rem",
                    fontFamily: "Roboto",
                  }}
                >
                  {entry.title}
                </Typography>
              </Box>
            ))}
            {metrics?.map((entry, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <MetricValue value={entry.value} type={entry.type} />
              </Box>
            ))}
          </Box>
        </>
      )}
      <Popover
        data-testid={"info-popover"}
        open={open && anchorEl?.id === INFO_BUTTON_ID}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: {
              boxShadow: "none",
              border: "solid 0.5px",
            },
          },
        }}
      />
      {/** show the team popover only on smaller screens */}
      {hasTeamPopup && (
        <Popover
          data-testid={"teams-popover"}
          open={open && anchorEl?.id === TEAM_BUTTON_ID}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          slotProps={{
            paper: {
              style: {
                width: "100%",
                boxShadow: "none",
                border: "solid 0.5px",
                borderColor: theme.palette.secondary.main,
              },
            },
          }}
        >
          {teamCard}
        </Popover>
      )}
      <Divider
        sx={{
          bgcolor: theme.palette.secondary.main,
          height: "1px",
          borderWidth: 0,
        }}
      />
      <Box sx={{ px: 4 }}>
        {progressBars?.map((entry, index) => (
          <ProgressBarWithTitleAndValue
            key={index}
            {...entry}
            customMargin={isMobile ? "40px 20px" : "30px 0px"}
          />
        ))}
      </Box>
    </Card>
  );
};

export default InformationCard;
