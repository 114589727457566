import { App, UniversalAppState } from "@pimo/pimo-app-builder";
import { formatDate } from "crq-utils";
import { OE } from "crq-types";
import { generatePath, matchPath } from "react-router-dom";

import { APP_ROUTES } from "./constants";
import { CRQOverlay } from "./components/overlay/crq-overlay";
import {
  initialCRQOverlayState,
  type CRQOverlayPartialAppState,
} from "./components/overlay/crq-overlay-initial-state";
import type { CRQAppState } from "./app";
import {
  fetchOEOverview,
  fetchOEs,
  fetchProgram,
  updateProgramReportingDate,
} from "../app/helpers/fetch-helpers";

export type OverlayPartialAppState = {
  OEs: OE[];
} & CRQOverlayPartialAppState &
  UniversalAppState;

export const initialOverlayAppState = {
  ...initialCRQOverlayState,
  OEs: [],
  isLoading: false,
} satisfies OverlayPartialAppState;

export const createOverlay = (app: App<CRQAppState>) => {
  const view = app.createOverlayView({ name: "overlay" });
  const overlay = view.addComponent({ component: CRQOverlay });

  overlay.on("overlay:toggle-sidebar", () => {
    const state = app.getAppState();

    app.setAppState({
      ...state,
      isSidebarOpen: !state.isSidebarOpen,
    });
  });

  overlay.on("overlay:click-logo", () => {
    app.navigate(`/`);
  });

  overlay.on("overlay:toggle-date-picker", async ({ payload }) => {
    if (!payload?.reportingDate) {
      return;
    }

    await updateProgramReportingDate(
      formatDate(new Date(payload?.reportingDate ?? ""))
    );
    const state = app.getAppState();
    const [OEs, OEOverview, program] = await Promise.all([
      fetchOEs(),
      fetchOEOverview(),
      fetchProgram(),
    ]);

    app.setAppState({
      ...state,
      OEs: OEs ?? [],
      OEOverview,
      program,
    });
  });

  overlay.mapState(({ isSidebarOpen, OEs, program, userProfile }) => ({
    hasSearchFunctionality: true,
    header: {
      logo: {
        big: "allianz.svg",
      },
      username: `${userProfile?.email ?? "n/a"}
      `,
    },
    canUserChangeCycle: true,
    menuEntries: [
      {
        icon: "clipboard.svg",
        level: 0,
        link: APP_ROUTES.overview,
        title: "OE Overview",
        onlyForAdmin: false,
      },
      {
        icon: "barchart.svg",
        items: OEs.map(({ name, id }) => {
          return {
            level: 1,
            link: generatePath(APP_ROUTES.report, { id: String(id) }),
            active: !!matchPath(
              `${generatePath(APP_ROUTES.report, { id: String(id) })}/*`,
              window.location.pathname
            ),
            title: name ?? "",
          };
        }),
        level: 0,
        title: "OE Reports",
      },
    ],
    isSidebarOpen,
    reportingDate: program?.reportingDate,
    userProfile,
  }));

  return overlay;
};
