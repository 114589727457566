import { CheckCircleRounded } from "@mui/icons-material";
import type { UpdateStatus } from "crq-types";
import type { FC } from "react";

import { STATUS_COLORS } from "../constants";

export type CRQStatusIndicatorProps = {
  status: UpdateStatus;
};

export const CRQStatusIndicator: FC<CRQStatusIndicatorProps> = ({ status }) => {
  return (
    <CheckCircleRounded
      sx={{
        color: STATUS_COLORS[status],
        backgroundColor: "white",
        borderRadius: 100,
      }}
    />
  );
};
