/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import {
  Box,
  Button,
  Grid,
  GridProps,
  styled,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import { Layout, PimoReactLayout } from "@pimo/pimo-app-builder";
import { generatePath, Link, useParams } from "react-router-dom";

const Stage = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.shape.borderRadius,
  "@media print": {
    backgroundColor: "unset",
    pageBreakBefore: "always",
  },
}));

export type TabsLayoutProps = {
  viewname?: string;
} & GridProps;

const buildTabLayoutReactComponent: (
  spacing: number,
  tabs: TabDefinition[],
  buttons:
    | {
        icon?: string;
        text: string;
        onClick: () => void;
      }[]
    | undefined
) => PimoReactLayout<TabsLayoutProps> =
  (spacing, tabs, buttons) =>
  ({ components }) => {
    const params = useParams();
    const theme = useTheme();

    const tabContentComponents = components.filter(
      (component) => component.layoutProps?.viewname === params.viewname
    );

    return (
      <Stage
        sx={{
          padding: theme.spacing(1),
          borderRadius: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs
            value={params.viewname}
            sx={{
              py: "10px",
              "@media print": {
                display: "none",
              },
            }}
          >
            {tabs.map((tab) => (
              <Tab
                sx={{ padding: "10px 16px" }}
                key={tab.viewname}
                component={Link}
                label={tab.name}
                value={tab.viewname}
                to={generatePath(tab.path, params)}
              />
            ))}
          </Tabs>

          {buttons && (
            <Box
              sx={{
                display: "flex",
                position: "relative",
                gap: 1,
                "@media print": {
                  display: "none",
                },
              }}
            >
              {buttons.map((button) => (
                <Button
                  onClick={button.onClick}
                  sx={{
                    padding: "0px 16px",
                    color: theme.palette.primary.dark,
                    borderCollapse: theme.palette.primary.dark,
                    borderRadius: "4px",
                    gap: 1,
                    display: window.location.pathname.includes("dashboard")
                      ? "inherit"
                      : "none",
                  }}
                  key={button.text}
                >
                  <>
                    {button.icon && (
                      <Box component={"img"} src={button.icon}></Box>
                    )}
                    {button.text}
                  </>
                </Button>
              ))}
            </Box>
          )}
        </Box>
        <Grid container spacing={spacing}>
          {tabContentComponents.map((component, index) => {
            const Component = component?.render();
            return (
              <Grid item key={`grid_item_${index}`} {...component.layoutProps}>
                <Component />
              </Grid>
            );
          })}
        </Grid>
      </Stage>
    );
  };
export interface TabDefinition {
  name: string;
  viewname: string;
  path: string;
}
export class TabLayout implements Layout<TabsLayoutProps> {
  constructor(
    private readonly tabs: TabDefinition[],
    private spacing = 1,
    private buttons?: {
      icon?: string;
      text: string;
      onClick: () => void;
    }[]
  ) {}

  getLayoutComponent() {
    return buildTabLayoutReactComponent(this.spacing, this.tabs, this.buttons);
  }
}
