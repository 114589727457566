import {
  Table,
  type App,
  type DeriveTableProps,
  type Plugin,
} from "@pimo/pimo-app-builder";
import {
  ChipCell,
  DateCell,
  GridLayout,
  IndexCell,
  NumberCard,
  TextCardCell,
} from "@pimo/pimo-components";
import type { CRQRun, CRQRunSuccess, FilterData } from "crq-types";
import {
  formatNumber,
  getLatestSuccessfulCRQRun,
  getUpdateStatus,
} from "crq-utils";
import { generatePath } from "react-router-dom";

import { CRQAppState } from "../app";
import { APP_ROUTES } from "../constants";
import { fetchOEOverview, fetchOEs } from "../helpers/fetch-helpers";
import { formatDate } from "crq-utils";
import { CheckBoxCell } from "../components/overview/checkbox-cell";
import { ProjectOverviewTitleCard } from "../components/project-overview-title-card/project-overview-title-card";
import { getMillionsString } from "../utils";

const DEFAULT_FILTER_DATA = {
  names: [],
  runs: [],
  search: "",
  status: [],
} satisfies FilterData;

export class OEOverviewPlugin implements Plugin<CRQAppState> {
  onRegister(app: App<CRQAppState>): void {
    const view = app.createView({
      name: "OE Overview",
      layout: new GridLayout(),
    });

    const titleCard = view.addComponent({
      component: ProjectOverviewTitleCard,
      layoutProps: {
        xs: 12,
      },
    });

    titleCard.mapState(({ filterDialogData, filterDialogOptions }) => {
      return {
        title: `OE Overview`,
        names: filterDialogOptions?.names ?? [],
        filterData: filterDialogData ?? DEFAULT_FILTER_DATA,
      };
    });

    titleCard.on("filter:clear", async () => {
      if (!app) {
        return;
      }

      const state: CRQAppState = app.getAppState();

      const OEOverview = await fetchOEOverview();
      const OEs = await fetchOEs();
      app.setAppState({ ...app.getAppState(), OEOverview, OEs });

      app.setAppState({
        ...state,
        OEs: OEs ?? [],
        OEOverview: OEOverview ?? [],
        filterDialogData: {
          names: [],
          runs: [],
          search: "",
          status: [],
        },
      });
    });

    titleCard.on("filter:apply", async ({ payload }) => {
      const state: CRQAppState = app.getAppState();

      const oeProjects = await fetchOEs(
        payload,
        state.program?.reportingDate
          ? formatDate(state.program.reportingDate)
          : undefined
      );

      const oeProjectOverview = await fetchOEOverview(
        payload,
        state.program?.reportingDate
          ? formatDate(state.program.reportingDate)
          : undefined
      );

      app.setAppState({
        ...state,
        filterDialogData: payload,
        OEOverview: oeProjectOverview,
        OEs: oeProjects ?? [],
      });
    });

    const numberOfOEsCard = view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12 / 4,
      },
    });

    numberOfOEsCard.mapState(({ OEOverview }) => {
      return {
        title: "Number of OEs",
        number: OEOverview?.numberOfOEs ?? 0,
      };
    });

    const lastCRQRunCard = view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12 / 4,
      },
    });

    lastCRQRunCard.mapState(({ OEOverview }) => {
      return {
        title: "Last Automated CRQ Run",
        number: OEOverview?.lastCRQRun
          ? formatDate(OEOverview.lastCRQRun)
          : "n/a",
      };
    });

    const percentageOfSuccessfulRunsCard = view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12 / 4,
      },
    });

    percentageOfSuccessfulRunsCard.mapState(({ OEOverview }) => {
      return {
        title: "Percentage of Successful Runs",
        number:
          OEOverview?.percentageOfSuccessfulRuns != null
            ? `${formatNumber({ number: OEOverview.percentageOfSuccessfulRuns })}%`
            : "n/a",
      };
    });

    const numberOfUpToDateQuestionnairesCard = view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12 / 4,
      },
    });

    numberOfUpToDateQuestionnairesCard.mapState(({ OEOverview }) => {
      return {
        title: "# of Up to Date Questionnaire",
        number: `${OEOverview?.numberOfUpToDateQuestionnaires ?? 0}/${
          OEOverview?.numberOfOEs ?? 0
        }`,
      };
    });

    const tableDefinition = [
      { component: IndexCell },
      { component: TextCardCell },
      { component: TextCardCell },
      { component: ChipCell },
      { component: ChipCell },
      { component: DateCell },
      { component: CheckBoxCell },
    ] as const;

    const table = new Table(tableDefinition, "overview");

    const tableComponent = view.addComponent<
      DeriveTableProps<typeof table>,
      unknown,
      unknown
    >({
      component: table,
      layoutProps: {
        xs: 12,
      },
    });

    tableComponent.mapState((state) => {
      return {
        tableProps: {
          sx: { height: window.screen.height < 1300 ? "76vh" : "88vh" },
        },

        data:
          state.OEs?.map(
            (oe, index): DeriveTableProps<typeof table>["data"][number] => {
              const { annualAverageLoss, date, lossEventProbability } =
                getLatestSuccessfulCRQRun(oe) ?? ({} as CRQRun & CRQRunSuccess);
              const status = getUpdateStatus(
                state.program?.reportingDate,
                oe.questionnaireResponse?.questionnaireSubmittedAt
              );

              return {
                rowProps: {
                  onClick: () => {
                    if (!oe?.id) {
                      return;
                    }

                    app?.navigate(
                      generatePath(APP_ROUTES.report, { id: String(oe.id) })
                    );
                  },
                },
                columnProps: [
                  {
                    indexValue: index + 1,
                    cardProps: { sx: { maxWidth: 60, minWidth: 60 } },
                  },
                  {
                    header: "OE",
                    body: oe.name ?? "",
                    cardProps: { sx: { maxWidth: 190, minWidth: 190 } },
                    tooltipShow: true,
                    tooltipTitle: oe.name,
                    bodyProps: {
                      sx: {
                        lineHeight: "1.2em",
                        height: "1.2em",
                        overflow: "hidden",
                        display: "-webkit-box",
                        lineBreak: "anywhere",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      },
                    },
                  },
                  {
                    header: "CRQ Contact",
                    tooltipShow: true,
                    tooltipTitle: "",
                    body: oe.questionnaireResponse?.contact ?? "",
                    cardProps: {
                      sx: {
                        maxWidth: 190,
                        minWidth: 190,
                      },
                    },
                    bodyProps: {
                      sx: {
                        lineHeight: "1.2em",
                        height: "1.2em",
                        overflow: "hidden",
                        display: "-webkit-box",
                        lineBreak: "anywhere",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      },
                    },
                  },
                  {
                    header: "Average Annual Loss",
                    body:
                      annualAverageLoss != null
                        ? getMillionsString({
                            currency: oe.questionnaireResponse?.currency,
                            number: +annualAverageLoss,
                          })
                        : "n/a",
                    chipProps: {
                      sx: {
                        borderRadius: "6px",
                        backgroundColor: `#2196F3`,
                        color: `white`,
                        maxWidth: 150,
                        minWidth: 150,
                      },
                    },
                  },

                  {
                    header: "1-in-20 Years Loss",
                    body:
                      lossEventProbability != null
                        ? getMillionsString({
                            currency: oe.questionnaireResponse?.currency,
                            number: +lossEventProbability,
                          })
                        : "n/a",
                    chipProps: {
                      sx: {
                        borderRadius: "6px",
                        backgroundColor: `#2196F3`,
                        color: `white`,
                        maxWidth: 150,
                        minWidth: 150,
                      },
                    },
                  },

                  {
                    header: "Last CRQ Run",
                    date: date ? formatDate(date) : "n/a",
                    icon: "calendar-outlined.svg",
                  },
                  {
                    header: "Last Questionnaire Update",
                    date: oe.questionnaireResponse?.questionnaireSubmittedAt
                      ? formatDate(
                          oe.questionnaireResponse.questionnaireSubmittedAt
                        )
                      : "n/a",
                    icon: "calendar-outlined.svg",
                    updateStatus: status ?? "unknown",
                  },
                ],
              };
            }
          ) ?? [],
      };
    });

    const route = app.createRoute({
      path: APP_ROUTES.overview,
      view,
    });

    route.on("load", async () => {
      const state = app.getAppState();
      const [OEOverview, OEs] = await Promise.all([
        fetchOEOverview(),
        fetchOEs(),
      ]);

      app.setAppState({
        ...state,
        OEOverview,
        OEs,
      });
    });
  }
}
