import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Popover,
  RegularBreakpoints,
} from "@mui/material";
import { type PropsWithChildren, type ReactNode, useState } from "react";

export type FormFieldWrapperProps = {
  infoSlot?: ReactNode;
  uiOptions?: {
    fieldSize?: RegularBreakpoints;
    skinnyLabel?: boolean;
  };
};

export const LayoutFieldWrapper: React.FC<
  PropsWithChildren<FormFieldWrapperProps>
> = ({ infoSlot, children, uiOptions: { fieldSize } = {} }) => {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null
  );

  return (
    <Grid item {...(fieldSize ?? { xs: 12 })}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ flex: "1 1 100%" }}>{children}</Box>
        {infoSlot && (
          <Box
            sx={{
              flex: "0 0 60px",
              display:
                infoSlot || !fieldSize || fieldSize?.xs === 12
                  ? "flex"
                  : "none",
              alignItems: "flex-start",
              justifyContent: "flex-end",
              paddingTop: "7px",
            }}
          >
            <>
              <IconButton
                onClick={(e) => setPopoverAnchor(e.target as HTMLButtonElement)}
              >
                <InfoOutlined />
              </IconButton>
              <Popover
                open={!!popoverAnchor}
                anchorEl={popoverAnchor}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                onClose={() => setPopoverAnchor(null)}
              >
                {infoSlot}
              </Popover>
            </>
          </Box>
        )}
      </Box>
    </Grid>
  );
};
