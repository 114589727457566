import type { CustomFormFields, CustomLayoutElements } from ".";
import type {
  BaseFormFieldDefinition,
  FormFieldDefinition,
} from "./form-field";
import type {
  BaseLayoutElementDefinition,
  LayoutElementDefinition,
} from "./layout-element";

type DeriveString<T> = T extends string ? T : never;

export class FormTab<
  FormValues,
  CustomFormFieldDefinitions extends CustomFormFields = never,
  CustomLayoutElementDefinitions extends CustomLayoutElements = never,
> {
  #definition: FormTabDefinition;

  #formFields: (
    | CustomFormFieldDefinitions[keyof CustomFormFieldDefinitions]
    | BaseFormFieldDefinition<
        DeriveString<
          FormFieldDefinition["type"] | keyof CustomFormFieldDefinitions
        >
      >
    | FormFieldDefinition
    | CustomLayoutElementDefinitions[keyof CustomLayoutElementDefinitions]
    | BaseLayoutElementDefinition<
        DeriveString<
          LayoutElementDefinition["type"] | keyof CustomLayoutElementDefinitions
        >
      >
    | LayoutElementDefinition
  )[] = [];

  #childTabs: FormTab<
    FormValues,
    CustomFormFieldDefinitions,
    CustomLayoutElementDefinitions
  >[] = [];

  #validationIndicatorSlot: React.ReactNode;
  #hasErrors = false;

  get formFields() {
    return this.#formFields;
  }

  get label() {
    return this.#definition.label;
  }

  get childTabs() {
    return this.#childTabs;
  }

  get validationIndicatorSlot() {
    return this.#validationIndicatorSlot;
  }

  get hasErrors() {
    return this.#hasErrors;
  }

  set hasErrors(value: boolean) {
    this.#hasErrors = value;
  }

  constructor(definition: FormTabDefinition) {
    this.#definition = definition;
    this.#validationIndicatorSlot = definition.validationIndicatorSlot;
  }

  addFormField<
    FieldDefinition extends
      | CustomFormFieldDefinitions[keyof CustomFormFieldDefinitions]
      | BaseFormFieldDefinition<
          DeriveString<
            FormFieldDefinition["type"] | keyof CustomFormFieldDefinitions
          >
        >
      | FormFieldDefinition,
  >(field: FieldDefinition) {
    this.#formFields.push(field);
  }

  addLayoutElement<
    LayoutDefinition extends
      | CustomLayoutElementDefinitions[keyof CustomLayoutElementDefinitions]
      | BaseLayoutElementDefinition<
          DeriveString<
            | LayoutElementDefinition["type"]
            | keyof CustomLayoutElementDefinitions
          >
        >
      | LayoutElementDefinition,
  >(element: LayoutDefinition) {
    this.#formFields.push(element);
  }

  addChildTab(
    definition: FormTabDefinition
  ): FormTab<
    FormValues,
    CustomFormFieldDefinitions,
    CustomLayoutElementDefinitions
  > {
    const tab = new FormTab<
      FormValues,
      CustomFormFieldDefinitions,
      CustomLayoutElementDefinitions
    >(definition);
    this.childTabs.push(tab);
    return tab;
  }
}

export interface FormTabDefinition {
  label: string;
  validationIndicatorSlot?: React.ReactNode;
  position?: number;
}
