import { SettingsOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  type SvgIconTypeMap,
  useTheme,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { FC, MouseEventHandler } from "react";
import { useState } from "react";

const accountSettingsPopoverIdentifier = "account-settings-popover";

export type HeaderAccountSettingsProps = {
  /** user display name */
  username?: string;
  /** renders entries as `ListItem`s */
  entries?: {
    /** the entry's `onClick` handler */
    onClick: MouseEventHandler<HTMLDivElement>;
    /** the entry's `icon`, should be a `@mui` icon */
    /* eslint-disable-next-line */
    icon: OverridableComponent<SvgIconTypeMap<any, "svg">> & {
      muiName: string;
    };
    /** the entry's displayed `text` */
    text: string;
  }[];
};

export const HeaderAccountSettings: FC<HeaderAccountSettingsProps> = ({
  entries = [],
  username,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const accountSettingsOpen = Boolean(anchorEl);
  const theme = useTheme();

  return (
    <Box data-testid="header-account-settings" display="inline">
      <Button
        aria-describedby="account-settings-popover"
        onClick={handleClick}
        data-testid="header-account-settings-button"
        sx={{
          borderColor: blue[50],
          borderRadius: theme.shape.borderRadius,
          padding: "7px 10px",
        }}
        endIcon={
          <SettingsOutlined sx={{ color: theme.palette.secondary.dark }} />
        }
        variant="outlined"
      >
        <Avatar sx={{ width: 24, height: 24 }} />
      </Button>
      <Popover
        id={accountSettingsPopoverIdentifier}
        open={accountSettingsOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        data-testid="header-account-settings-popover"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ "@media print": { display: "none" } }}
      >
        <Box>
          <List>
            {username && (
              <>
                <ListSubheader>{username}</ListSubheader>
                <Divider />
              </>
            )}
            {entries.map(({ icon: Icon, onClick, text }) => (
              <ListItem key={text}>
                <ListItemButton onClick={onClick}>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </Box>
  );
};
