export const FORM_FIELD_INFO_TEXT = {
  oeName: "The name of your Allianz OE",
  contact:
    "Please provide your contact person for cyber risk quantification (CRQ).",
  urls: 'Please enter the main domains of your Allianz entity (e.g. "allianz.com" or "www.allianz.com"). You can enter up to 10 domains.',
  annualRevenue: `
  The annual revenue is defined as total revenue of the Allianz entity under review on a yearly basis. This can be based on the total revenue from the corresponding income statement.

  Please type in a number in the currency below. In case your needed currency is unavailable, please convert the annual revenue to a EUR-amount by using the current exchange rate.`,
  currency: `The currency is defined as the currency in which the annual revenue is denominated. This is the currency that was selected during the initial collection of input data in H1 2024. If you would like to change the currency, please contact isrm@allianz.com.`,
  numberOfEmployees: `The Number of Employees is defined as the number of employees of the corresponding Allianz entity. 

Please note that you do not have to provide an exact number and can choose between the ranges in the list.`,
  industriesOfOperation: `The industries in which the Allianz entity is active. Please consider only the industries of your OE itself (e.g. Insurance Carriers) and not the industries of your clients.
  
  Please select the industries from the list based on the SIC Classification (multiple dropdown). 
  `,
  countriesOfOperation: `The country where the Allianz entity provides goods and services or has operations running from. That means, it can be seen as the location of the legal entities and not where the costumers are located.
  
  Please select the countries from the list (multiple dropdown). 
  `,
  usStatesOfOperation: `If the Allianz entity operates from or has operations in a US state or territory, then these US states and territories need to be selected.
  
  Please select the US states from the list (multiple dropdown).`,
  numberOfEmployeeEndpoints:
    "The number of endpoints used by employees in your Allianz entity. This can be defined as unique IPs from laptops/desktops, not including mobile devices like mobiles or iPads. Virtual clients should also be included.",

  canEmployeesAccessPHIData: `Payment Card Industry (PCI) records are cardholder data and/or sensitive authentication data. Cardholder data may also appear in the form of any of the following: primary account number and also referred to as current account number and unique payment card number (cardholder name, expiration date, service code).	
    
  Personally Identifiable Information (PII) is information that, when used alone or with other relevant data, can identify an individual. PII record is any data record or piece of information that includes a full name, social security number or any other unique citizen identifier, driver’s license, financial information, passport information or medical records.
    
  Protected Health Information (PHI) records are any individually identifiable information relating to the past, present, or future health status of an individual that is created, collected, or transmitted, or maintained by an entity in relation to the provision of healthcare, payment for healthcare services, or use in healthcare operations. This includes any health-related records including records that are defined under the Health Insurance Portability and Accountability Act (HIPAA) framework as Protected Health Information.
    `,

  percProductivityEmployees: `The percentage of the operation process that will be affected in the event that your employees' endpoints suffers a failure, interruption or a breach.   
    A good rule of thumb would be to ask something like - if 100% of the employees would lose access to their device, would that translate to around 100% decrease in productivity?	
    `,
  percIncomeEmployees: `The percentage of your company's revenue stream that will be affected in the event that your employees' endpoints suffers a failure, interruption, or a breach. 
    
  A good rule of thumb would be to ask something like - if 100% of the employees would lose access to their device, would that translate to around 100% decrease in revenue? 
  The idea is to differentiate between companies for which the employees are the main source of income (e.g. a law firm) and businesses where the employees are not the main source of income (e.g. e-commerce).	
    `,
  nrPHIOnPremise: `
  Please consider the number of data records stored in your on-premises infrastructure. Please note:
  
  •	If you have multiple systems where you store the same record, you need to count all of them. For example, if you have a client's Passport ID stored in 3 databases, you need to count 3 data records, not 1.
  
  •	Please also include employee data records (e.g. under PII).
  
  For example, to estimate the number of data records you might consult with the Data Privacy team and / or consider e.g. the number of policies / clients / employees and the number of systems containing the same data records.

  `,
  maxRecordsOnPremise: `The maximum number of the records above (PCI, PII and PHI), stored in the same table or in the same database that is running and can be accessed from one place. 

Can be up to the sum of PII + PCI + PHI.`,
  percProductivityOnPremise: `The percentage of the operation process that will be affected in the event that the infrastructure suffers a failure, interruption or a breach.
    The main idea is to understand how much of the infrastructure that is associated with productivity is on-prem and how much is in the cloud. For example, if all of the critical systems / data associated with productivity are on-prem, you could answer 100%. If there is redundancy with the cloud, the answer may be lower.`,
  percIncomeOnPremise: `
  The percentage of your company's revenue stream that will be affected in the event that the infrastructure suffers a failure, interruption, or a breach.
The main idea is to understand how much of the infrastructure that is associated with income is on-prem and how much is in the cloud. For example, if all of the critical systems / data associated with income are on-prem, you could answer 100%. If there is redundancy with the cloud, the answer may be lower.
`,
  nrPHICloud: `
  Please consider the number of data records stored in your cloud. Please note:
  
  •	If you have multiple systems where you store the same record, you need to count all of them. For example, if you have a client's Passport ID stored in 3 databases, you need to count 3 data records, not 1.
  
  •	Please also include employee data records (e.g. under PII).
  
  For example, to estimate the number of data records you might consult with the Data Privacy team and / or consider e.g. the number of policies / clients / employees and the number of systems containing the same data records.
  `,
  maxRecordsCloud: `The maximum number of the records above (PCI, PII and PHI), stored in the same table or in the same database that is running and can be accessed from one place. 

Can be up to the sum of PII + PCI + PHI.`,
  percProductivityCloud: `The percentage of the operation process that will be affected in the event that the cloud suffers a failure, interruption or a breach.
    The main idea is to understand how much of the infrastructure that is associated with productivity is on-prem and how much is in the cloud. For example, if all of the critical systems / data associated with productivity are in the cloud, you could answer 100%.	
    `,
  percIncomeCloud: `The percentage of your company's revenue stream that will be affected in the event that the cloud suffers a failure, interruption, or a breach.
    The main idea is to understand how much of the infrastructure that is associated with income is on-prem and how much is in the cloud. For example, if all of the critical systems associated with income are in the cloud, you could answer 100%.	
    `,
  outageDurationMatImpact: `Material impact is defined as a loss of more than 10% of the Allianz entity's monthly income. Please consider an outage of critical business operations.`,
  networkInterruptionRestorationTime: `The time it takes to bring the systems back to the original form prior to the incident. Please refer to this question as a major outage, and not a minor one.`,
  CISControl: `
  Please specify 1 out of the 4 levels of implementation for each CIS control. Please consider the info boxes for more details and the remarks below.
  
  Kovrr’s model currently uses the **CIS control framework (version 7.1)** to capture your entity's security posture. The CIS controls input is an **implementation group level** (also referred to as IG). There are 3 IG levels and the level “Not Implemented”, each representing an increasing maturity level of the given control implementation in the organization.
  
  Each IG identifies a subset of the CIS sub-controls and each IG builds upon the previous one. As such, IG2 includes IG1, and IG3 includes all of the CIS sub-controls in IG1 and IG2. Based on the implemented CIS sub-controls and their corresponding implementation group level (see info boxes), you can determine the IG level for all 20 CIS Controls.
  
  **Example for CIS Control 1 (Inventory and Control of Hardware Assets)**
  
  For example, if the CIS Sub-Controls 1.4 (Maintain Detailed Asset Inventory) and 1.6 (Address Unauthorized Assets) are implemented you are in IG 1 for the CIS Control 1. If the CIS Sub-Controls 1.1 & 1.3-1.7 are implemented you are in IG 2 for CIS Control 1. If all CIS Sub-Controls 1.1 - 1.8 are implemented you are in IG3 for CIS Control 1.
  
  **Please note:** The Sub-Controls must be applied to the entire entity as such; if this is only partially fulfilled, please do not consider the specific Sub-Control to be implemented.

  `,
  CISControl1: `**CIS Control 1** - Inventory and Control of Hardware Assets

Actively manage (inventory, track, and correct) all hardware devices on the network so that only authorized devices are given access, and unauthorized and unmanaged devices are found and prevented from gaining access.`,
  CISControl2: `**CIS Control 2** - Inventory and Control of Software Assets

Actively manage (inventory, track, and correct) all software on the network so that only authorized software is installed and can execute, and that unauthorized and unmanaged software is found and prevented from installation or execution.`,
  CISControl3: `**CIS Control 3** - Continuous Vulnerability Management

Continuously acquire, assess, and take action on new information in order to identify vulnerabilities, remediate, and minimize the window of opportunity for attackers.`,
  CISControl4: `**CIS Control 4** - Controlled Use of Administrative Privileges

The processes and tools used to track/control/prevent/correct the use, assignment, and configuration of administrative privileges on computers, networks, and applications.`,
  CISControl5: `**CIS Control 5** - Secure Configuration for Hardware and Software on Mobile Devices, Laptops, Workstations and Servers

Establish, implement, and actively manage (track, report on, correct) the security configuration of mobile devices, laptops, servers, and workstations using a rigorous configuration management and change control process in order to prevent attackers from exploiting vulnerable services and settings.`,
  CISControl6: `**CIS Control 6** - Maintenance, Monitoring and Analysis of Audit Logs

Collect, manage, and analyze audit logs of events that could help detect, understand, or recover from an attack.`,
  CISControl7: `**CIS Control 7** - Email and Web Browser Protections

Minimize the attack surface and the opportunities for attackers to manipulate human behavior though their interaction with web browsers and email systems.`,
  CISControl8: `**CIS Control 8** - Malware Defenses

Control the installation, spread, and execution of malicious code at multiple points in the enterprise, while optimizing the use of automation to enable rapid updating of defense, data gathering, and corrective action.`,
  CISControl9: `**CIS Control 9** - Limitation and Control of Network Ports, Protocols, and Services

Manage (track/control/correct) the ongoing operational use of ports, protocols, and services on networked devices in order to minimize windows of vulnerability available to attackers.`,
  CISControl10: `**CIS Control 10** - Data Recovery Capabilities

  The processes and tools used to properly back up critical information with a proven methodology for timely recovery of it.`,
  CISControl11: `**CIS Control 11** - Secure Configuration for Network Devices, such as Firewalls, Routers and Switches

Establish, implement, and actively manage (track, report on, correct) the security configuration of network infrastructure devices using a rigorous configuration management and change control process in order to prevent attackers from exploiting vulnerable services and settings.`,
  CISControl12: `**CIS Control 12** - Boundary Defense

Detect/prevent/correct the flow of information transferring networks of different trust levels with a focus on security-damaging data.`,
  CISControl13: `**CIS Control 13** - Data Protection

The processes and tools used to prevent data exfiltration, mitigate the effects of exfiltrated data, and ensure the privacy and integrity of sensitive information.`,
  CISControl14: `**CIS Control 14** - Controlled Access Based on the Need to Know

The processes and tools used to track/control/prevent/correct secure access to critical assets (e.g., information, resources, systems) according to the formal determination of which persons, computers, and applications have a need and right to access these critical assets based on an approved classification.`,
  CISControl15: `**CIS Control 15** - Wireless Access Control

The processes and tools used to track/control/prevent/correct the security use of wireless local area networks (WLANs), access points, and wireless client systems.`,
  CISControl16: `**CIS Control 16** - Account Monitoring and Control

Actively manage the life cycle of system and application accounts - their creation, use, dormancy, deletion - in order to minimize opportunities for attackers to leverage them.`,
  CISControl17: `**CIS Control 17** - Implement a Security Awareness and Training Program

For all functional roles in the organization (prioritizing those mission-critical to the business and its security), identify the specific knowledge, skills and abilities needed to support defense of the enterprise; develop and execute an integrated plan to assess, identify gaps, and remediate through policy, organizational planning, training, and awareness programs.`,
  CISControl18: `**CIS Control 18** - Application Software Security

Manage the security life cycle of all in-house developed and acquired software in order to prevent, detect, and correct security weaknesses.`,
  CISControl19: `**CIS Control 19** - Incident Response and Management

Protect the organization's information, as well as its reputation, by developing and implementing an incident response infrastructure (e.g., plans, defined roles, training, communications, management oversight) for quickly discovering an attack and then effectively containing the damage, eradicating the attacker's presence, and restoring the integrity of the network and systems.`,
  CISControl20: `**CIS Control 20** - Penetration Tests and Red Team Exercises

Test the overall strength of an organization's defense (the technology, the processes, and the people) by simulating the objectives and actions of an attacker.`,
} as const;
